/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {verify} from './core/_requests'
import JwtService from '../../services/JwtService'
import Swal from 'sweetalert2'
import * as actionCreators from '../../redux/actions/index'
import * as updateAction from '../../redux/actions/update'
// import supervision_list from '../../services/supervisions.json'
// import office_title from '../../services/office_title.json'
import axios from 'axios'
const AuthPage = () => {
  const [isLoading, setLoading] = useState(true)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const access_token = urlParams.get('access_token')
  const id_token = urlParams.get('id_token')
  useEffect(() => {
    if (!id_token) {
      setLoading(false)
      window.location.href = `${process.env.REACT_APP_STRAPI_URL}/api/auth/login`
    } else {
      verify(id_token).then(async (response) => {
        await JwtService.saveAccessToken(id_token)
        await JwtService.saveJwT(response.data.jwt)
        await JwtService.saveUserID(response.data.user.id) 
        let email = response.data.user.email
        // let isSupervisor = supervision_list.filter((item) => item.supervisor === email)
        // let isSupervisee = supervision_list.filter((item) => item.supervisees.includes(email))
        // if(isSupervisor.length > 0) {
        //   let data = isSupervisor[0]
        //   let user = await actionCreators.getExistingUser(data.supervisees)
        //   if(user && user.length > 0) {
        //     let ids = user.map((item) => {return item.id})
        //     await updateAction.upsertEmployeeSupervision(ids, response.data.user.id)
        //     await updateAction.updatePPRFormManagers(ids, response.data.user.id)
        //   }
        // }
        // if(isSupervisee.length > 0){
        //   let data = isSupervisee[0]
        //   let supervisor = data.supervisor
        //   let user = await actionCreators.getExistingUser([supervisor])
        //   if(user && user.length > 0){
        //     await updateAction.upsertEmployeeSupervision([response.data.user.id], user[0].id)
        //     await updateAction.updatePPRFormManagers([response.data.user.id], user[0].id)
        //   }
        // }
        let info = JwtService.getUserInfo()
        // let office_title_info = office_title.filter(item => item.email === email)
        // if(office_title_info.length > 0) await updateAction.updateOfficeandTitle(response.data.user.id, office_title_info[0])
        await updateAction.updateUserInfo(response.data.user.id, info)
        setLoading(false)
        Swal.fire({
          text: 'You have successfully logged in!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-primary',
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setLoading(false)
            if (JwtService.getToken())
              window.location.href = `${process.env.REACT_APP_URL}/dashboard`
          }
        })
      })
    }
  }, [])

  return (
    <>
      {!access_token ? (
        <>
        {/* <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        >
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <a href='#'>
              <img alt='Logo' src={toAbsoluteUrl('/eval_logo_fullcolor.png')} className='h-200px' />
            </a>
            <div className='w-lg-500px pt-10 mx-auto'>
              <div className='text-center mb-20'>
                
                <p className='text-dark mb-3' style={{whiteSpace: 'nowrap', fontSize: '30px'}}><b><span style={{fontSize: '38px'}}>P</span>ROPERTY<span style={{fontSize: '38px'}}> D</span>ATABASE</b></p>
              </div>
              <div className='text-center'>
                <a
                  href={`${process.env.REACT_APP_STRAPI_URL}/api/connect/auth0`}
                  className='btn btn-flex flex-center btn-dark btn-lg' style={{width: '321px'}}
                >
                  Login with Microsoft
                </a>
              </div>
            </div>
          </div>
        </div> */}
        </>
      ) : (
        <>
          {isLoading ? (
            <div className='d-flex flex-row flex-column-fluid'>
              <div className='d-flex flex-row-fluid bg-dark bg-opacity-5 flex-center'>
                <div className='spinner-border text-primary w-150px h-150px'></div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}


export {AuthPage}
