//@ts-nocheck
import {lazy,FC,Suspense,useEffect,useState} from 'react'
import {Route,Routes,Navigate,useLocation,useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {DashboardWrapper} from '../pages/DashboardPage'
import {useSelector} from 'react-redux'
import {AdminWrapper} from '../pages/admin/AdminWrapper'
import user_view_permissions from '../services/user_view_permissions.json'
const PrivateRoutes = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [allowedIds,setAllowedIds] = useState([])
  const currentUser = useSelector((state) => state.ppr.currentUser)
  useEffect(() => {
    if(process.env.REACT_APP_UNDER_MAINTENANCE === 'true') {
      navigate('/error/maintenance')
    }
    else if(currentUser && !currentUser.is_president && !currentUser.is_vicepres) {
      if(allowedIds.length > 0) {
        if(location.pathname.includes('/profile')) {
          var id
          if(location.pathname.includes('/profile/overview')) {
            id = location.pathname.split('/overview/').pop()?.split('/')[0]
          } else {
            id = location.pathname.split('/ppr/').pop()?.split('/')[0]
          }
          if(!allowedIds.includes(parseInt(id))) {
            navigate('/error/404')
          }
        }
        else if(location.pathname.includes('/admin')) {
          if(!currentUser.is_admin) {
            navigate('/error/404')
          }
        }
      }
    }
  },[allowedIds])
  useEffect(() => {
    if(currentUser) {
      const permissions = user_view_permissions.filter((item) => item.user_id === currentUser.id)
      setAllowedIds((old) => [...old,currentUser.id])
      if(currentUser.supervisees && currentUser.supervisees.length > 0) {
        currentUser.supervisees.forEach((staff) => {
          setAllowedIds((old) => [...old,staff.employee.id,currentUser.id])
        })
      }
      if(permissions.length > 0) {
        permissions.forEach((item) => {
          setAllowedIds((old) => [...old,...item.allowed_to_view])
        })
      }
    }

  },[currentUser])
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='admin' element={<AdminWrapper />} />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
        <Route path='*' element={<Navigate to='/error/Maintenance' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
