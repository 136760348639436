/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React, {useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import * as actionCreators from '../../../../app/redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import JwtService from '../../../../app/services/JwtService'
import moment from 'moment'


type Props = {
  className: string
  chartHeight: string
}

const ListsWidget5: React.FC<Props> = ({className, chartHeight}) => {
  const dispatch = useDispatch()
  const activityLogs = useSelector((state) => state.ppr.activityLogs)
  const userId = JwtService.getUserID()

  

  const actionText = (action) => {
    if (action === 'created ppr form') return 'created'
    if (action === 'update') return 'updated'
    if (action === 'upload') return 'uploaded'
    if (action === 'view') return 'viewed'
    if (action === 'delete') return 'deleted'
    if (action === 'submitted ppr form') return 'submitted'
    if (action === 'approved') return 'approved'
    if (action === 'Autosaved ppr form') return 'autosaved'
  }

  const getPart = (part) => {
    if (part === null) return
    if (part === 'approvals') return
    else return `${part.toUpperCase()} of `
  }

  const getUserText = (by) => {
    if (by.data.id === parseInt(userId)) return 'You'
    else return by.data.attributes.full_name
  }

  const getUserColor = (action) => {
    if (action === 'created ppr form') return <i className='fa fa-genderless text-success fs-1'></i>
    if (action === 'update') return <i className='fa fa-genderless text-primary fs-1'></i>
    if (action === 'upload') return <i className='fa fa-genderless text-info fs-1'></i>
    if (action === 'view') return <i className='fa fa-genderless text-warning fs-1'></i>
    if (action === 'delete') return <i className='fa fa-genderless text-danger fs-1'></i>
    if (action === 'approved') return <i className='fa fa-genderless text-info fs-1'></i>
    if (action === 'Autosaved ppr form') return <i className='fa fa-genderless text-primary fs-1'></i>
    if (action === 'submitted ppr form') return <i className='fa fa-genderless text-success fs-1'></i>
  }

  const getUserDef = (owner) => {
    if (owner.data) {
      if (owner.data.id === parseInt(userId)) return 'your'
      else return `${owner.data.attributes.full_name}'s`
    }
  }
  const getTimeFrame = (date) => {
    let today = moment()
    if (today.diff(date, 'days') >= 7) {
      return moment(date).format('ll')
    } else {
      return moment(date)
        .fromNow()
        .replace('minute', 'min')
        .replace('minutes', 'mins')
        .replace('months', 'mos')
        .replace('a few seconds ago', 'a secs ago')
    }
  }
  useEffect(() => {
    const fetch = async () => {
      await actionCreators.getActivities(dispatch, JwtService.getUserID())
    }
    if (JwtService.getUserID()) {
      fetch()
    }
  }, [])

  return (
    <>
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Activities</span>
        </h3>
      </div>
      {/* <span className='fw-bold mb-2 text-muted ps-9' style={{marginTop: '-15px'}}>
          No Activities Available
        </span> */}
      {!activityLogs || activityLogs.length === 0 ? (
        <span className='fw-bold mb-2 text-muted ps-9' style={{marginTop: '-15px'}}>
          No Activities Available
        </span>
      ) : (
        <>
          <div className='card-body pt-0' style={{overflowY: 'auto', maxHeight: chartHeight}}>
            <div className='card-body pt-5 fw-bold' style={{padding: '10px'}}>
              <div className='timeline-label'>
                {activityLogs.map((data, index) => {
                  if (index < 20) {
                    return (
                      <div className='timeline-item' key={index}>
                        <div
                          className='timeline-label fw-bold text-gray-800 fs-6'
                          style={{whiteSpace: 'nowrap'}}
                        >
                          {getTimeFrame(data.attributes.createdAt)}
                        </div>
                        <div className='timeline-badge'>{getUserColor(data.attributes.action)}</div>
                        <div className='fw-bold text-gray-800 ps-3'>
                        {data.attributes.action === 'Autosaved ppr form' ? (
                          <span>{getUserText(data.attributes.by)} have been idle and {getUserDef(data.attributes.owner)} PPR Form for {moment(data.attributes.data.attributes && data.attributes.data.attributes.date_review_end).year()} has been {actionText(data.attributes.action)}.</span>
                        ) : (
                          <span>
                          {getUserText(data.attributes.by)} {actionText(data.attributes.action)}{' '}
                          {getPart(data.attributes.part)}
                          {getUserDef(data.attributes.owner)} PPR Form for{' '}
                          {data.attributes.data ? data.attributes.data.attributes ? moment(data.attributes.data.attributes && data.attributes.data.attributes.date_review_end).year() : '2022' : '2022'} 
                          </span>
                          
                        )}

                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
          <div
            className='modal-container pb-7 pt-5'
            style={{marginLeft: 'auto', marginRight: 'auto'}}
          >
            <button
              type='button'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#staticBackdrop'
            >
              View More
            </button>
            <div
              className='modal fade'
              id='staticBackdrop'
              data-bs-backdrop='static'
              data-bs-keyboard='false'
              tabIndex='-1'
              aria-labelledby='staticBackdropLabel'
              aria-hidden='true'
            >
              <div className='modal-dialog modal-dialog-centered'>
                <div
                  className='modal-content'
                  style={{
                    position: 'absolute',
                    right: '-100px',
                    width: '800px',
                  }}
                >
                  <div className='modal-header'>
                    <h5 className='modal-title'>ACTIVITY LOG</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='card-body pt-0' style={{overflowY: 'auto'}}>
                      <div
                        className='card-body pt-5 fw-bold'
                        style={{padding: '10px', maxHeight: chartHeight}}
                      >
                        <div className='timeline-label'>
                          {activityLogs &&
                            activityLogs.map((data, index) => {
                              return (
                                <div className='timeline-item' key={index}>
                                  <div
                                    className='timeline-label fw-bold text-gray-800 fs-6'
                                    style={{whiteSpace: 'nowrap'}}
                                  >
                                    {getTimeFrame(data.attributes.createdAt)}
                                  </div>
                                  <div className='timeline-badge'>
                                    {getUserColor(data.attributes.action)}
                                  </div>
                                  <div className='fw-bold text-gray-800 ps-3'>
                                    {getUserText(data.attributes.by)}{' '}
                                    {actionText(data.attributes.action)}{' '}
                                    {getPart(data.attributes.part)}
                                    {getUserDef(data.attributes.owner)} PPR Form for{' '}
                                    {data.attributes.data ? data.attributes.data.attributes ? moment(data.attributes.data.attributes && data.attributes.data.attributes.date_review_end).year() : '2022' : '2022'}
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* end::Header */}
      {/* begin::Body */}

      {/* end: Card Body */}
    </div>
    </>
  )
}

export {ListsWidget5}
