/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
//@ts-nocheck
import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import JwtService from '../services/JwtService'
import {logout} from '../modules/auth/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import * as actionCreators from '../redux/actions'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const dispatch = useDispatch()
  const [initial, setInitial] = useState(false)
  const currentUser = useSelector((state) => state.ppr.currentUser)
  window.localStorage.setItem('kt_theme_mode_value', 'light')
  const isExpired = JwtService.VerifyTokenExpiration()
  if (!currentUser) {
    actionCreators.getUserById(dispatch)
    actionCreators.getPreviousUserPPRForms(new Date().getFullYear(),dispatch)
  }
  if (isExpired) {
    logout()
    window.location.href = `${process.env.REACT_APP_URL}/auth`
  }
  useEffect(() => {
    if (!initial) {
      if (process.env.REACT_APP_STAGING === 'true') {
        if(!JwtService.getUserID()){
          JwtService.saveUserID(77)
        }
      }
      setInitial(true)
    }
  }, [initial])
  const isMaintenance = process.env.REACT_APP_MAINTENANCE_BOOL
  let isAuthenticated = process.env.REACT_APP_STAGING === 'true' ? true : JwtService.getToken()
  
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isMaintenance === 'true' ? (<Route path='*' element={<Navigate to='/error/Maintenance' />} />) :
            isAuthenticated ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
