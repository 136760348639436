let defaultState = {
  supervisedStaff: null,
  currentUser: null,
  currentUserSupervisor: null,
  currentUserOffice: null,
  currentUserTitle: null,
  currentYearPPRForm: null,
  previousYearPPRForm: null,
  currentYearPPRFormByYear: null,
  allCurrentYearPPRFormByYear: null,
  partOneResult: null,
  partOneAResult: null,
  partTwoResult: null,
  partTwoAResult: null,
  selectedPPRForm: null,
  partFiveResult: null,
  partSixResult: null,
  currentPresident: null,
  allSupervisedStaff: null,
  activityLogs: null,
  notifications: null,
  userList: null,
  jobTitles: null,
  offices: null,
  pprFormYear: null,
  pprFormCurrentYear: null,
  userAssignments: null,
}
const pprReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SUPERVISED_STAFF':
      return {
        ...state,
        supervisedStaff: action.data,
      }
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.data,
      }
    case 'SET_CURRENT_USER_SUPERVISOR':
      return {
        ...state,
        currentUserSupervisor: action.data,
      }
    case 'SET_CURRENT_USER_TITLE':
      return {
        ...state,
        currentUserTitle: action.data,
      }
    case 'SET_CURRENT_USER_OFFICE':
      return {
        ...state,
        currentUserOffice: action.data,
      }
    case 'SET_CURRENT_YEAR_PPR_FORM':
      return {
        ...state,
        currentYearPPRForm: action.data,
      }
      case 'SET_PREVIOUS_YEAR_PPR_FORM':
      return {
        ...state,
        previousYearPPRForm: action.data,
      }
    case 'SET_CURRENT_YEAR_PPR_FORM_BY_YEAR':
      return {
        ...state,
        currentYearPPRFormByYear: action.data,
      }
    case 'SET_ALL_CURRENT_YEAR_PPR_FORM':
      return {
        ...state,
        allCurrentYearPPRFormByYear: action.data,
      }
    case 'SET_PART1_RESULT':
      return {
        ...state,
        partOneResult: action.data,
      }
    case 'SET_PART1A_RESULT':
      return {
        ...state,
        partOneAResult: action.data,
      }
    case 'SET_PART2_RESULT':
      return {
        ...state,
        partTwoResult: action.data,
      }
    case 'SET_PART2A_RESULT':
      return {
        ...state,
        partTwoAResult: action.data,
      }
    case 'SET_SELECTED_PPR_FORM':
      return {
        ...state,
        selectedPPRForm: action.data,
      }
    case 'SET_PART5_RESULT':
      return {
        ...state,
        partFiveResult: action.data,
      }
    case 'SET_PART6_RESULT':
      return {
        ...state,
        partSixResult: action.data,
      }
    case 'SET_CURRENT_PRESIDENT':
      return {
        ...state,
        currentPresident: action.data,
      }
    case 'SET_ALL_SUPERVISED_STAFF':
      return {
        ...state,
        allSupervisedStaff: action.data,
      }
    case 'SET_ACTIVITY_LOGS':
      return {
        ...state,
        activityLogs: action.data,
      }
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.data,
      }
    case 'SET_ALL_USERS':
      return {
        ...state,
        userList: action.data,
      }
    case 'SET_ALL_JOB_TITLES':
      return {
        ...state,
        jobTitles: action.data,
      }
    case 'SET_ALL_OFFICES':
      return {
        ...state,
        offices: action.data,
      }
    case 'SET_PPR_FORM_YEAR':
      return {
        ...state,
        pprFormYear: action.data,
      }
    case 'SET_PPR_FORM_YEAR_BY_YEAR':
      return {
        ...state,
        pprFormCurrentYear: action.data,
      }
    case 'SET_USER_ASSIGNMENTS':
        return {
          ...state,
          userAssignments: action.data,
        }
    default:
      return state
  }
}

export default pprReducer
