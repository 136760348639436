/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React,{useEffect,useState, useRef } from 'react'
import {KTSVG,toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import * as actionCreators from '../../../../app/redux/actions'
import {useSelector,useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const pprFormYear = useSelector((state) => state.ppr.pprFormYear)
  const [yearList,setYearList] = useState()
  const dispatch = useDispatch()
  const [search,setSearch] = useState('')
  const [progress,setProgress] = useState('0')
  const allCurrentYearPPRFormByYear = useSelector((state) => state.ppr.allCurrentYearPPRFormByYear)
  const allSupervisedStaff = useSelector((state) => state.ppr.allSupervisedStaff)
  const [staff,setStaff] = useState()
  const [isFetching,setFetching] = useState(true)
  const [year,setYear] = useState()
  const [loading,setLoading] = useState(false)
  const [showNonBlocked, setShowNonBlocked] = useState(false);
  const getPage = () => {
    return `${year}`
  }
  useEffect(() => {
    const fetch = async () => {
      setFetching(true)
      await actionCreators.getAllCurrentYearPPRForm(year,dispatch)
      await actionCreators.getAllSupervision(dispatch)
      setFetching(false)
    }
    if(loading) {
      fetch()
    }

  },[year])
  
  useEffect(() => {
    if(pprFormYear) {
      var result = [];
      for(var i in pprFormYear)
        if(pprFormYear[i].attributes.is_active) {
          result.push([pprFormYear[i].attributes.label]);
        }
      const years = result.toString().split(',')
      setYearList(years)
      if(year === undefined) {
        setYear(years[0])
      }
      setLoading(true)
    }
  })
 
  const getExistingPPR = (employee_id) => {
    let obj =
      allCurrentYearPPRFormByYear &&
      allCurrentYearPPRFormByYear.filter((item) => item.attributes.employee.data && item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      if(obj[0].attributes.is_initial) {
        if(obj[0].attributes.president_approval) {
          return '100%'
        } else if(obj[0].attributes.manager_approval) {
          return '66%'
        } else if(obj[0].attributes.employee_approval) {
          return '33%'
        } else {
          return 'In-Progress'
        }
      } else {
        return 'Not yet submitted'
      }
    } else {
      return 'Not yet submitted'
    }
  }
  const getRatingPPR = (employee_id) => {
    let obj =
    allCurrentYearPPRFormByYear &&
    allCurrentYearPPRFormByYear.filter((item) => item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      if(obj[0].attributes.is_initial) {
        if(obj[0].attributes.president_rating_level){
          return obj[0].attributes.president_rating_level
        }
        else if(obj[0].attributes.supervisor_rating_level) {
          return obj[0].attributes.supervisor_rating_level
        }
        else if(obj[0].attributes.rating_level) {
          return obj[0].attributes.rating_level
        } 
        else{
          return 'No Rating Data'
        }
      } else {
        return 'Not yet submitted'
      }
    } else {
      return 'Not yet submitted'
    }
  }
  const getOriginalRatingPPR = (employee_id) => {
    let obj =
    allCurrentYearPPRFormByYear &&
    allCurrentYearPPRFormByYear.filter((item) => item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      if(obj[0].attributes.rating_level) {
        return obj[0].attributes.rating_level
      } else {
        return 'No Rating Data'
      }
    } else {
      return 'Not yet submitted'
    }
  }
  const getPart1OverallRating = (employee_id)=>{
    let obj =
    allCurrentYearPPRFormByYear &&
    allCurrentYearPPRFormByYear.filter((item) => item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      return obj[0].attributes.ppr_form_ones.data
    }
  }
  const getPart1AOverallRating = (employee_id)=>{
    let obj =
    allCurrentYearPPRFormByYear &&
    allCurrentYearPPRFormByYear.filter((item) => item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      return obj[0].attributes.ppr_form_onea_s.data
    }
  }
  const getPart2OverallRating = (employee_id)=>{
    let obj =
    allCurrentYearPPRFormByYear &&
    allCurrentYearPPRFormByYear.filter((item) => item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      return obj[0].attributes.ppr_form_twos.data
    }
  }

  useEffect(() => {
    if(search) {
      const result = allSupervisedStaff.filter((item) =>
        item.attributes.employee.data.attributes.full_name
          .toLowerCase()
          .includes(search.toLowerCase())
      )
      setStaff(result)
    } else {
      setStaff(allSupervisedStaff)
    }
  },[search])
  useEffect(() => {
    if(allSupervisedStaff) setStaff(allSupervisedStaff)
  },[allSupervisedStaff])
  useEffect(() => {
    if(progress) {
      if(progress === 'Not yet submitted') {
        let ids = allCurrentYearPPRFormByYear.map((item) => {
          return !item.attributes.is_initial && item.attributes.employee.data.id
        })
        allSupervisedStaff.forEach((i) => {
          let obj =
            allCurrentYearPPRFormByYear &&
            allCurrentYearPPRFormByYear.filter(
              (item) => item.attributes.employee.data.id === i.attributes.employee.data.id
            )
          if(obj.length === 0) {
            ids.push(i.attributes.employee.data.id)
          }
        })
        const result = allSupervisedStaff.filter((item) =>
          ids.includes(item.attributes.employee.data.id)
        )
        setStaff(result)
      }
      if(progress === 'In-Progress') {
        let ids = allCurrentYearPPRFormByYear.map((item) => {
          return (
            item.attributes.is_initial &&
            !item.attributes.president_approval &&
            item.attributes.employee.data.id
          )
        })
        const result = allSupervisedStaff.filter((item) =>
          ids.includes(item.attributes.employee.data.id)
        )
        setStaff(result)
      }
      if(progress === 'Completed') {
        let ids = allCurrentYearPPRFormByYear.map((item) => {
          return item.attributes.president_approval && item.attributes.employee.data.id
        })
        const result = allSupervisedStaff.filter((item) =>
          ids.includes(item.attributes.employee.data.id)
        )
        setStaff(result)
      }
      if(progress === '0') {
        setStaff(allSupervisedStaff)
      }
    }
  },[progress])

  const [data, setData] = useState([]);
  const [editableItemId, setEditableItemId] = useState(null);
  const [modified, setModified] = useState(false);
  useEffect(() => {
    if (staff && staff.length > 0) {
      const mappedData = staff.map((data, index) => {
        /////////////////////////////
        let total = 0;
        let overall = [];
        let result1 = {}
        const part1Data= getPart1OverallRating(
          data.attributes.employee.data &&
          data.attributes.employee.data.id
          )
        if (part1Data) {
          const result = part1Data;
          Object.keys(result).forEach((key) => {
            if (result[key].attributes.weight && result[key].attributes.supervisor_rating) {
              const A = parseFloat(result[key].attributes.weight);
              const F = parseFloat(result[key].attributes.supervisor_rating);
              total += (A || 0);
              overall.push((A / 100) * F);
            }
          });
        }
        // Compute average overall rating
        let average = 0;
        if (overall.length > 0) {
          overall.forEach((val) => {
            average += val;
          });
        }
        result1 = parseInt(average.toFixed(2))
        ///////////////////////////////
        const part1Adata =getPart1AOverallRating(
          data.attributes.employee.data &&
          data.attributes.employee.data.id
          )
        const selectedScores= part1Adata
        let result1A = 0
        if (selectedScores) {
          let average3 = 0;
          let count3 = 0; 
          Object.keys(selectedScores).forEach((key) => {
            if (parseInt(selectedScores[key]['attributes']['rating_criteria']) !== -1) {
              average3 += parseInt(selectedScores[key]['attributes']['rating_criteria']);
              count3++;
            }
          });
          if (count3 !== 0) {
            result1A = (average3 / count3);
          } else {
            result1A = 0;
          }
        }
        //////////////////////////////
        const excludedTitles = [
          "1. Delegation",
          "2. Leadership",
          "3. Staff Development",
          "4. Business & Financial Management"
        ];
        let overall2 = [];
        let result2 = 0;
        const part2Data = getPart2OverallRating(data.attributes.employee.data?.id);
        if (Array.isArray(part2Data) && part2Data.length > 0) {
          const selectedOption = part2Data.filter(item => {
            if (item && item.attributes && item.attributes.title) {
              return !excludedTitles.some(title => item.attributes.title.includes(title));
            } else {
              return false;
            }
          });
          selectedOption.forEach(option => {
            if (option.attributes.rating_supervisor !== '') {
              overall2.push(option.attributes.rating_supervisor);
            }
          });
          if (overall2.length > 0) {
            const sum = overall2.reduce((acc, val) => acc + parseInt(val), 0);
            result2 = sum / overall2.length;
          }
        }
        ////////////////////////////////////
        let finalResult = 0
        let finalWhole = 0
        if(result1 && result1A && result2) {
          let total = (parseFloat(result1) * .5) + (parseFloat(result1A) * .15) + (parseFloat(result2) * .25) + .5
          finalResult = (total.toFixed(2))
          finalWhole=  Math.round(total)
        }
        ////////////////////////////////////
        return {
        id: index + 1,
        name: `${data.attributes.employee.data &&
          data.attributes.employee.data.attributes.firstname}`,
        position: `${data.attributes.employee.data &&
          data.attributes.employee.data.attributes.job_assignments.data[0] &&
          data.attributes.employee.data.attributes.job_assignments.data[0].attributes.job_title.data.attributes.name || ''}`,
        office: `${data.attributes.employee.data && 
          data.attributes.employee.data.attributes.office_assignments.data.length > 0 &&
          data.attributes.employee.data.attributes.office_assignments.data[0].attributes.office.data &&
          data.attributes.employee.data.attributes.office_assignments.data[0].attributes.office.data.attributes.name
            ? data.attributes.employee.data.attributes.office_assignments.data[0].attributes.office.data.attributes.name
            : ''}`,
        progress: data.attributes.employee.data &&
        data.attributes.employee.data.id,
        ratingLevel:`${getRatingPPR(
          data.attributes.employee.data &&
          data.attributes.employee.data.id
        )}` !== 'No Rating Data' ? `${getRatingPPR(
          data.attributes.employee.data &&
          data.attributes.employee.data.id
        )}` : finalWhole ,
        originalRating: `${getOriginalRatingPPR(
          data.attributes.employee.data &&
          data.attributes.employee.data.id)}` !== 'No Rating Data' ?
          `${getOriginalRatingPPR(
            data.attributes.employee.data &&
            data.attributes.employee.data.id)}`
            : finalResult ,
        blocked: data.attributes.employee?.data?.attributes?.blocked,
        result1: result1,
        result1A: result1A,
        result2: result2,
        }
      });
      setData(mappedData);
    }
  }, [staff]);

  const handleEdit = (id, field, value) => {
    setData(prevData => {
      const newData = [...prevData];
      const index = newData.findIndex(item => item.id === id);
      newData[index][field] = value;
      setModified(true);
      return newData;
    });
  };
  const handleStartEditing = (id) => {
    setEditableItemId(id);
  };

  const handleFinishEditing = () => {
    setEditableItemId(null);
  };
  const handleExport = () => {
    setModified(false);
  };

  const handleExportAndFinishEditing = () => {
    handleFinishEditing();
    handleExport();
  };

  const handleSwitchChange = () => {
    setShowNonBlocked((prev) => !prev);
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Council Staff PPR</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>All councils staff PPR submitted</span>
        </h3>
        <button
            type='button'
            className='btn btn-sm btn-icon btn-primary'
            style={{
              width: '10%',
              marginLeft: 'auto', 
              marginRight: 'auto',
              marginTop: '8px',
              display: 'block', 
            }}
            data-bs-toggle='modal'
            data-bs-target='#exportModalStaff'
          >
            Export Rating
          </button>
          
          <div className='modal fade' tabIndex={-1} id='exportModalStaff'>
          <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content' style={{width: '95%',marginLeft: '2rem'}} >
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>Export {year}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
                </div>
              </div>
              <div className="modal-body" style={{ display: 'flex' ,overflowY: 'auto',height: '700px'}}>
              <div className='table-responsive tableFixHead' style={{overflowY: 'auto',height: '100%',width: '100%', borderRadius: '5px'}}>
              <table id='ExportTable' className='table table-bordered align-middle gs-0 gy-4'>
          <thead>
            <tr style={{ backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 1, borderBottom: '1px solid #ddd' }}>
              <th className='p-2'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Name</span>
                  </div>
                </div>
              </th>
              <th className='p-2'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Position</span>
                  </div>
                </div>
              </th>
              <th className='p-2'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Office</span>
                  </div>
                </div>
              </th>
              <th className='p-2'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Progress</span>
                  </div>
                </div>
              </th>
              <th className='p-2'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Rating Level</span>
                  </div>
                </div>
              </th>
              <th className='p-2'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Original Rating</span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data
            .filter(
              (data) =>
                !showNonBlocked || !data.blocked
            )
            .map((item) => (
              <tr key={item.id}>
                   <td className='p-2 border'>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) => handleEdit(item.id, 'name', e.target.value)}
                      onBlur={handleFinishEditing}
                    />
                  ) : (
                    <div onClick={() => handleStartEditing(item.id)}>
                      {item.name}
                    </div>
                  )}
                </td>
                <td className='p-2 border'>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={item.position}
                      onChange={(e) => handleEdit(item.id, 'position', e.target.value)}
                      onBlur={handleFinishEditing}
                    />
                  ) : (
                    <div onClick={() => handleStartEditing(item.id)}>
                      {item.position}
                    </div>
                  )}
                </td>
                <td className='p-2 border'>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={item.office}
                      onChange={(e) => handleEdit(item.id, 'office', e.target.value)}
                      onBlur={handleFinishEditing}
                    />
                  ) : (
                    <div onClick={() => handleStartEditing(item.id)}>
                      {item.office}
                    </div>
                  )}
                </td>
                <td className='p-3 border'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-semibold'>{`${getExistingPPR(
                                      item.progress
                                    )}`}</span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    <div
                                      className='progress-bar bg-primary'
                                      role='progressbar'
                                      style={{
                                        width: `${getExistingPPR(item.progress
                                        ).includes('%')
                                          ? getExistingPPR(
                                            item.progress
                                          )
                                          : '0%'
                                          }`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                </td>
                   <td className='p-2 border'>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={item.ratingLevel}
                      onChange={(e) => handleEdit(item.id, 'ratingLevel', e.target.value)}
                      onBlur={handleFinishEditing}
                    />
                  ) : (
                    <div onClick={() => handleStartEditing(item.id)}>
                      {item.ratingLevel}
                    </div>
                  )}
                </td>
                <td className='p-2 border'>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={item.originalRating}
                      onChange={(e) => handleEdit(item.id, 'originalRating', e.target.value)}
                      onBlur={handleFinishEditing}
                    />
                  ) : (
                    <div onClick={() => handleStartEditing(item.id)}>
                      {item.originalRating}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            </div>
            </div>
            <div className="modal-footer">
            <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
              Cancel
            </button>
            {modified ? (
                <button
                  type='button'
                  className='btn btn-success btn-sm'
                  onClick={handleExportAndFinishEditing}
                >
                  Save
                </button>
              ) : (
                <ReactHTMLTableToExcel
                  className='btn btn-primary btn-sm'
                  table='ExportTable'
                  filename={`Rating Report Staff for ${year}`}
                  sheet={`Rating ${year}`}
                  buttonText='Export to Excel'
                  onClick={handleExport}
                />
              )}
            </div>
          </div>
        </div>
      </div>

        <div className='card-toolbar'>
          <ul className='nav'>
            {yearList && yearList.map((name,index) => (
              <li className='nav-item' key={index}>
                <a
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${index === 0 ? 'active' : ''
                    }`}
                  data-bs-toggle='tab'
                  href={`#kt_table_widget_5_tab${index}`}
                  onClick={() => setYear(name)}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 /> */}
        </div>
      </div>
      {isFetching ? (
        <div className='card-body py-3'>
          <div className='row pt-2 pb-3'>
            <div className='col-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search by Employee'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <div className='col-2'>
              <select
                className='form-select'
                aria-label='Select example'
                defaultValue={progress}
                onChange={(e) => {
                  setProgress(e.target.value)
                }}
              >
                <option value='0'>Filter by Progress</option>
                <option value='Completed'>Completed</option>
                <option value='In-Progress'>In-Progress</option>
                <option value='Not yet submitted'>Not yet submitted</option>
              </select>
            </div>
          </div>
          <div className="container-body2">
            <div className="post-box2">
              <div className="avatar2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="avatar2-action"></div>
            </div>
          </div>
          <div className="container-body2">
            <div className="post-box2">
              <div className="avatar2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="avatar2-action"></div>
            </div>
          </div>
          <div className="container-body2">
            <div className="post-box2">
              <div className="avatar2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="avatar2-action"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className='card-body py-3'>
          <div className='row pt-2 pb-3'>
            <div className='col-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search by Employee'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <div className='col-2'>
              <select
                className='form-select'
                aria-label='Select example'
                defaultValue={progress}
                onChange={(e) => {
                  setProgress(e.target.value)
                }}
              >
                <option value='0'>Filter by Progress</option>
                <option value='Completed'>Completed</option>
                <option value='In-Progress'>In-Progress</option>
                <option value='Not yet submitted'>Not yet submitted</option>
              </select>
            </div>
            <div className="col-3 mt-4 form-check form-switch">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  onChange={handleSwitchChange} checked={showNonBlocked}/>
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show Active </label>
            </div>
          </div>
          <div className='tab-content'>
            {yearList && yearList.map((name,index) => (
              <div
                className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
                id={`kt_table_widget_5_tab${index}`}
                key={index}
              >
                <div className='table-responsive'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                data-kt-check='true'
                                data-kt-check-target='.widget-9-check'
                              />
                            </div>
                          </th>
                          <th className='min-w-150px'>Staff</th>
                          <th className='min-w-140px'>Status</th>
                          <th className='min-w-140px'>Office</th>
                          <th className='min-w-120px'>Progress</th>
                          <th className='min-w-100px text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staff &&
                          staff.length > 0 &&
                          staff
                          .filter(
                            (data) =>
                              !showNonBlocked || !data.attributes.employee?.data?.attributes?.blocked
                          )
                          .map((data, index) => (
                            <tr key={index}>
                              <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-9-check'
                                    type='checkbox'
                                    value='1'
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-45px me-5'>
                                    <img
                                      src={
                                        data.attributes.employee.data && data.attributes.employee.data.attributes.image
                                          ? data.attributes.employee.data.attributes.image
                                          : toAbsoluteUrl('/media/avatars/blank.png')
                                      }
                                      alt='metronic'
                                    />
                                  </div>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {getExistingPPR(data.attributes.employee.data && data.attributes.employee.data.id) === '100%' ?
                                      <Link
                                        to={`/profile/ppr/${data.attributes.employee.data && data.attributes.employee.data.id
                                          }/${getPage()}`}
                                        className='text-dark fw-bold text-hover-primary fs-6'
                                      >
                                        {data.attributes.employee.data &&
                                          data.attributes.employee.data.attributes.firstname}{' '}
                                        {data.attributes.employee.data &&
                                          data.attributes.employee.data.attributes.lastname}
                                      </Link> :
                                      <Link
                                        to={`/profile/ppr/${data.attributes.employee.data && data.attributes.employee.data.id
                                          }/${getPage()}`}
                                        className='text-dark fw-bold text-hover-primary fs-6'
                                      >
                                        {data.attributes.employee.data &&
                                          data.attributes.employee.data.attributes.firstname}{' '}
                                        {data.attributes.employee.data &&
                                          data.attributes.employee.data.attributes.lastname}
                                      </Link>}
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {data.attributes.employee.data && data.attributes.employee.data.attributes.job_assignments.data[0] &&
                                        data.attributes.employee.data.attributes.job_assignments.data[0]
                                          .attributes.job_title.data.attributes.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {data.attributes.employee?.data?.attributes?.blocked ? (
                                  <span className='badge badge-light-danger'>Inactive</span>
                                ) : (
                                  <span className='badge badge-light-success'>Active</span>
                                )}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {data.attributes.employee.data && data.attributes.employee.data.attributes.office_assignments.data
                                    .length > 0 &&
                                    data.attributes.employee.data.attributes.office_assignments
                                      .data[0].attributes.office.data &&
                                    data.attributes.employee.data.attributes.office_assignments
                                      .data[0].attributes.office.data.attributes.name}
                                </span>
                              </td>
                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-semibold'>{`${getExistingPPR(
                                      data.attributes.employee.data &&
                                      data.attributes.employee.data.id
                                    )}`}</span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    <div
                                      className='progress-bar bg-primary'
                                      role='progressbar'
                                      style={{
                                        width: `${getExistingPPR(
                                          data.attributes.employee.data &&
                                          data.attributes.employee.data.id
                                        ).includes('%')
                                          ? getExistingPPR(
                                            data.attributes.employee &&
                                            data.attributes.employee.data.id
                                          )
                                          : '0%'
                                          }`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex justify-content-center flex-shrink-0'>
                                  <Link
                                    to={`/profile/ppr/${data.attributes.employee.data &&
                                      data.attributes.employee.data.id
                                      }/${getPage()}`}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen021.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {staff && staff.length === 0 && <span>No data found</span>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export {TablesWidget5}
