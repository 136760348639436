//@ts-nocheck
import {FC,useEffect,useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../_metronic/layout/core'
import * as actionCreators from '../redux/actions/index'
import * as updateAction from '../redux/actions/update'
import * as createAction from '../redux/actions/create'
import {
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget5,
  TablesWidget10,
  TablesWidget5,
} from '../../_metronic/partials/widgets'
import {useSnackbar} from 'react-simple-snackbar'

const DashboardPage: FC = () => {
  const [overallRating,setOverAllRating] = useState()
  const [updateAssign,setUpdateAssign] = useState()
  const currentUser = useSelector((state) => state.ppr.currentUser)
  const loginedUser = window.localStorage.getItem('user_id')
  const options = {
    style: {
      backgroundColor: '#2196F3', // Material blue color
      color: '#FFF', // White text
    },
    closeStyle: {
      color: '#FFF',
      backgroundColor: '#BBDEFB', // Lighter shade of blue for the close button
      borderRadius: '50%', // Rounded shape for the button
      padding: '8px', // Added padding for better touch area
      cursor: 'pointer', // Change cursor to indicate clickability
    },
  }
  const [openSnackbar, closeSnackbar] = useSnackbar(options)
  useEffect(() => {
    if(currentUser){
      actionCreators.getUserAssignmentsLogin(currentUser.email)
        .then(async data => {
          await setUpdateAssign(data);
        })
        .catch(error => {
          console.log(error); 
        });
    }
  }, [currentUser]);
  
  useEffect(() => {
    const getLatestPPR = async () => {
      let result = await actionCreators.getAllCurrentUserPPRForms()
      setOverAllRating(result)
    }
    if(currentUser) {
      getLatestPPR()
    }
  },[currentUser])

// useEffect(()=>{
//   if(updateAssign && updateAssign && updateAssign !== null){
//     if(!updateAssign[0].attributes.newSupervisorExist){
//      let result =createAction.createUserfromAssignments(updateAssign[0].attributes.new_supervisor_email.replace(/@(gmail\.com|usasean\.org)/, ""),'12345678',updateAssign[0].attributes.new_supervisor_email,updateAssign[0].attributes.new_supervisor_name,updateAssign[0].id,currentUser.id)
//     console.log(result)
//     openSnackbar(
//       "There were changes in your current assignment.",  
//       10000
//     )
//     }
//     else if(!updateAssign[0].attributes.isApplied){
//       updateAction.updateAssignments(currentUser.id,'1',updateAssign[0].attributes.new_supervisor.data.id)
//       updateAction.updateUserAssignmentsLogin(updateAssign[0].id)
//       openSnackbar(
//         "There were changes in your current assignment.",  
//         10000
//       )
//     }
//   else{
//     openSnackbar(
//       "There were no changes in your current assignment.",
//       5000
//     )
//   }
// }
// },[updateAssign])
 


  return (
    <>
      {currentUser && currentUser.isSupervisor && (
        <>
          <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-4'>
            <MixedWidget11
                className='card-xxl-stretch-100 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='175px'
              />
              {overallRating &&
                (overallRating.attributes.rating_level || overallRating.attributes.supervisor_rating_level || overallRating.attributes.president_rating_level) && (
                  <MixedWidget10
                    className='card-xxl-stretch-100 mb-5 mb-xl-8'
                    chartColor='primary'
                    chartHeight='150px'
                    overAllRating={overallRating}
                  />
                )}
  
            </div>
            <div className='col-xxl-4'>
              <ListsWidget5 className='card-xxl-stretch' chartHeight='410px'/>
            </div>
            <div className='col-xl-4'>
              <ListsWidget2 className='card-xl-stretch mb-xl-8' />
            </div>
          </div>
          <div className='row gy-5 g-xl-12'>
            <div className='col-xl-12'>
              <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
            </div>
          </div>
        </>
      )}
      {currentUser && currentUser.isEmployee && (
        <>
          <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-4'>
              <MixedWidget11
                className='card-xxl-stretch-100 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='175px'
              />
              {overallRating &&
                (overallRating.attributes.rating_level || overallRating.attributes.supervisor_rating_level || overallRating.attributes.president_rating_level) && (
                  <MixedWidget10
                    className='card-xxl-stretch-100 mb-5 mb-xl-8'
                    chartColor='primary'
                    chartHeight='150px'
                    overAllRating={overallRating}
                  />
                )}
            </div>
            <div className='col-xxl-4'>
              <ListsWidget5 className='card-xxl-stretch'
                chartHeight='410px' />
            </div>
          </div>
        </>
      )}
      {currentUser && (currentUser.is_president || currentUser.is_vicepres) && (
        <>
          <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-4'>
              {overallRating &&
                (overallRating.attributes.rating_level || overallRating.attributes.supervisor_rating_level || overallRating.attributes.president_rating_level) && (
                  <MixedWidget10
                    className='card-xxl-stretch-100 mb-5 mb-xl-8'
                    chartColor='primary'
                    chartHeight='150px'
                    overAllRating={overallRating}
                  />
                )}
              <MixedWidget11
                className='card-xxl-stretch-100 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='175px'
              />
            </div>
            <div className='col-xxl-4'>
              <ListsWidget5 className='card-xxl-stretch' chartHeight='410px'/>
            </div>
            <div className='col-xl-4'>
              <ListsWidget2 className='card-xl-stretch mb-xl-8' />
            </div>
          </div>
          <div className='row gy-5 g-xl-12'>
            <div className='col-xl-12'>
              <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
            </div>
          </div>
          <div className='row gy-5 g-xl-8'>
            <div className='col-xl-12'>
              <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-8' />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
