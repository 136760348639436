/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import Admin from '../../modules/admin/Admin'
const AdminPage: FC = () => (
  <>
    <Admin />
  </>
)

const AdminWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADMIN'})}</PageTitle>
      <AdminPage />
    </>
  )
}

export {AdminWrapper}
