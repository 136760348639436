//@ts-nocheck
import React, { FC, useEffect, useRef, useState } from 'react';
import {useSelector} from 'react-redux'
import { Modal } from 'bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import Swal from 'sweetalert2';
import * as actionCreators from '../../../redux/actions/update';

type SyncEntryProps = {
  setRefetch: () => void;
  user: Record<string, unknown>; 
  createUser: Function;
};

const SyncEntry: FC<SyncEntryProps> = ({ setRefetch, user, createUser }) => {
  const userList = useSelector((state) => state.ppr.userList)
  const myModalRef = useRef<HTMLDivElement>(null);
  const [myModal, setMyModal] = useState<Modal | null>(null);
  const currentYearPPRForm = useSelector((state) => state.ppr.currentYearPPRForm)
  const [loading, setLoading] = useState(false);
  const [userExist, setUserExist] = useState(false);

  useEffect(() => {
    if (myModalRef.current) {
      setMyModal(new Modal(myModalRef.current));
    }
  }, []);

  useEffect(() => {
    console.log(userList)
    if(userList){
    const isUserExist = userList.some(item => item.id === user.id);
    setUserExist(isUserExist);
    }
  },[user,userList])

  const handleShow = (user) => {
    const isUserExist = userList.some(item => item.email === user.attributes.email);
    setUserExist(isUserExist);
    console.log(isUserExist)
    if (myModal) {
      myModal.show();
    }
  };

  const handleHide = () => {
    if (myModal) {
      myModal.hide();
    }
  };
  const handleSync = (id,user) => {
    setLoading(true);
    Swal.fire({
      title: 'Confirm Sync',
      text: `Are you sure you want to sync details for ${user.fullname}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, sync it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
      try{
        myModal.hide();
        actionCreators.updateUserFromUserAssignment(id,user,currentYearPPRForm)

      }
      catch(error){
        Swal.fire({
          title: 'Error!',
          text: `There was an error syncing details for ${user.fullname}.${<br/>} Error: ${error}`,
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then(() => {
          myModal.hide();
        })
      }finally{
        setLoading(false);
      }
    }
    });
  };

  const handleCreate = (id,user) => {
    setLoading(true);
    Swal.fire({
      title: 'Confirm Sync',
      text: `Are you sure you want to Create User: ${user.fullname}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, create it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
    if (result.isConfirmed) {
    const email = user.email;
    const parts = email.split("@");
     createUser(parts[0], process.env.REACT_APP_USER_DEFAULT_PASSWORD, user.email, user.fullname)
    setLoading(false);
    setRefetch(true)
    setRefetch(false)
    }
    else{
      setLoading(false);
    }
    });
  }
  console.log(userExist)
  return (
    <>
      {console.log(loading)}
      <button
        className='btn btn-sm btn-icon btn-primary'
        style={{ whiteSpace: 'nowrap', width: '80px' }}
        onClick={()=> handleShow(user)}
      >
        Sync
      </button>

      <div className='modal fade' ref={myModalRef} tabIndex={-1} id='createEntryModal'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header' style={{ height: '15%' }}>
              <h5 className='modal-title'>Sync {'🡪'} User & User Assignments</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleHide}
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
          <div className='modal-body'>
            <div className='user-info'>
              <div>
                <h2>
                  <strong>Full Name:</strong> {user.attributes.fullname}
                </h2>
              </div>
              <div>
                <h2>
                  <strong>Supervisor:</strong> {user.attributes.new_supervisor.data ? user.attributes.new_supervisor.data.attributes.full_name : 'N/A'}
                </h2>
              </div>
              {!user.attributes.newSupervisorExist ? (
                <>
                  <div>
                    <h2>
                      <strong>Pending Supervisor Creation Status:</strong> {!user.attributes.newSupervisorExist ? 'Active' : 'Inactive'}
                    </h2>
                  </div>
                  <div>
                    <h2>
                      <strong>Pending Supervisor Name:</strong> {user.attributes.new_supervisor_name ? user.attributes.new_supervisor_name : 'N/A'}
                    </h2>
                  </div>
                  <div>
                    <h2>
                      <strong>Pending Supervisor Email:</strong> {user.attributes.new_supervisor_email ? user.attributes.new_supervisor_email : 'N/A'}
                    </h2>
                  </div>
                </>
              ) : ''}
              <div>
                <h2>
                  <strong>Office:</strong> {user.attributes.office.data ? user.attributes.office.data.attributes.name : 'N/A'}
                </h2>
              </div>
              <div>
                <h2>
                  <strong>Job Title:</strong> {user.attributes.job_title.data ? user.attributes.job_title.data.attributes.name : 'N/A'}
                </h2>
              </div>
            </div>

            <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => handleSync(user.id, user.attributes)}
              disabled={loading}
            >
              {loading ? 'Syncing...' : 'Sync'}
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => handleCreate(user.id, user.attributes)}
              disabled={loading || userExist}
            >
              {loading ? 'Creating...' : 'Create'}
            </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SyncEntry;
