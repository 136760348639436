/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import clsx from 'clsx'
import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs} from '../../../helpers'
import JwtService from '../../../../app/services/JwtService'
import * as actionCreators from '../../../../app/redux/actions/index'
import * as updateAction from '../../../../app/redux/actions/update'
const HeaderNotificationsMenu: FC = () => {
  const currentUser = useSelector((state) => state.ppr.currentUser)
  const notifications = useSelector((state) => state.ppr.notifications)
  const activityLogs = useSelector((state) => state.ppr.activityLogs)
  const userId = currentUser && currentUser.id
  const dispatch = useDispatch()
  const getNotifications = async (id) => {
    await actionCreators.getAllNotifications(id, dispatch)
  }
  const setReadNotifcation = async (id, path) => {
    await updateAction.readNotification(id)
    getNotifications(currentUser.id)
    window.open(path,'_blank')
  }
  useEffect(() => {
    if (currentUser) {
      getNotifications(currentUser.id)
    }
  }, [currentUser])

  useEffect(() => {
    const fetch = async () => {
      await actionCreators.getActivities(dispatch, JwtService.getUserID())
    }
    if (JwtService.getUserID()) {
      fetch()
    }
  }, [])
  
  const getUserText = (by) => {
    if (by.data.id === parseInt(userId)) return 'You'
    else return by.data.attributes.full_name
  }
  const getUserDef = (owner) => {
    if (owner.data) {
      if (owner.data.id === parseInt(userId)) return 'your'
      else return `${owner.data.attributes.full_name}'s`
    }
    else{
      return ''
    }
  }
  const getPart = (part) => {
    if (part === null) return
    if (part === 'approvals') return
    else return `${part.toUpperCase()} of `
  }
  const actionText = (action) => {
    if (action === 'created ppr form') return 'created'
    if (action === 'update') return 'updated'
    if (action === 'upload') return 'uploaded'
    if (action === 'view') return 'viewed'
    if (action === 'delete') return 'deleted'
    if (action === 'submitted ppr form') return 'submitted'
    if (action === 'approved') return 'approved'
    if (action === 'Autosaved ppr form') return 'autosaved'
  }

  const combinedList = [
    ...(notifications?.map((item) => ({ ...item, type: 'notification' })) ?? []),
    ...(activityLogs?.map((item) => ({ ...item, type: 'activityLog' })) ?? []),
  ]
  
const sortedList = combinedList.sort((a, b) => {
  if (a.type < b.type) return -1
  if (a.type > b.type) return 1
  if (a.date < b.date) return -1
  if (a.date > b.date) return 1
  return 0
})
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-8 mb-8'>
          Notifications{' '}
          <span className='fs-8 opacity-75 ps-3'>
            {sortedList && sortedList.length} reports
          </span>
        </h3>
      </div>
      <div className='tab-content'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {sortedList &&
            sortedList.map((log, index) => (
              (log.type == 'notification'? 
              <div key={`log${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center me-2'>
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setReadNotifcation(
                        log.id,
                        toAbsoluteUrl(
                          `/profile/ppr/${log.attributes.ppr_form_owner.data.id}/2022${
                            log.attributes.section ? `#${log.attributes.section}` : ''
                          }`
                        )
                      )
                    }}
                  >
                    <span className='text-gray-800 text-hover-primary fw-bold'>
                      <div dangerouslySetInnerHTML={{__html: log.attributes.email_content}} />
                    </span>

                    <span className='badge badge-light'>
                      {moment(log.attributes.createdAt).fromNow()}
                    </span>
                  </span>
                </div>
              </div>
                : 
                <div key={`log${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center me-2'>
                <span
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        const ownerId = log.attributes.owner.data && log.attributes.owner.data.id;
                        const url = `/profile/ppr/${ownerId}/${moment(log.attributes.updatedAt).year()}${log.attributes.part ? `#${log.attributes.part}` : ''}`;
                        window.location.assign(url);
                      }}
                    >
                      <span className='text-gray-800 text-hover-primary fw-bold'>
                      <div dangerouslySetInnerHTML={{__html: 
                      `${log.attributes.action === 'Autosaved ppr form' ? `${getUserText(log.attributes.by)} have been idle and ${getUserDef(log.attributes.owner)} PPR Form for ${moment(log.attributes.data.attributes && log.attributes.data.attributes.date_review_end).year()} has been ${actionText(log.attributes.action)}.`
                      :`${getUserText(log.attributes.by)} ${actionText(log.attributes.action)} ${' '}
                      ${getPart(log.attributes.part) === undefined ? '' : getPart(log.attributes.part)}
                      ${getUserDef(log.attributes.owner)} PPR Form for ${' '}
                      ${log.attributes.data ? log.attributes.data.attributes ? moment(log.attributes.data.attributes && log.attributes.data.attributes.date_review_end).year() : '2022' : '2022'}.`}
                      Please note that you are able to make changes to your submission until it is marked as completed.`}} />
                    </span>
                      

                    <span className='badge badge-light'>
                      {moment(log.attributes.createdAt).fromNow()}
                    </span>
                    
                  </span>
                </div>
              </div>)
              
            ))}
        </div>
        <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
