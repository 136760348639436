//@ts-nocheck
import {FC, useEffect, useState} from 'react'
import * as actionCreators from '../../../../app/redux/actions/index'
import * as updateAction from '../../../../app/redux/actions/update'
import * as createAction from '../../../../app/redux/actions/create'
import {useDispatch, useSelector} from 'react-redux'
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {KTSVG} from '../../../../_metronic/helpers'
import '../styles.css'

const YearConfiguration: FC = () => {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState()
  const [loader, setLoader] = useState(false)
  const [isActivated, setIsActivated] = useState()
  const [isCompleted,setIsCompleted]= useState()
  const [isRating,setIsRating]= useState()
  const [selectedDate,setSelectedDate]= useState()
  const [isCreated, setIsCreated]= useState()
  const [fields, setFields]= useState({
    active: '',
    complete: '',
    rating: ''
  })
  const pprFormYear = useSelector((state) => state.ppr.pprFormYear)
  const fetch = async () => {
    setLoader(true)
    await actionCreators.getPPRFormYear(dispatch)
    setLoader(false)
  }
  useEffect(() => {
    fetch()
  }, [])
  const handleDate = async (e) => {
    setLoader(true)
    setIsActivated(false)
    setIsCompleted(false)
    setIsCreated(false)
    setIsRating(false)
    setStartDate(e)
    let result =
      (await pprFormYear) &&
      pprFormYear.filter((year) => year.attributes.label === `${e.toString().split(' ', 4)[3]}`)
    if (result && result.length > 0) {
      setIsActivated(result[0].attributes.is_active)
      setIsCompleted(result[0].attributes.is_completed)
      setIsRating(result[0].attributes.start_rating)
      setIsCreated(true)
    } else {
      setIsActivated(false)
      setIsCompleted(false)
      setIsRating(false)
      setIsCreated(false)
    }
    setLoader(false)
  }
  const handleDateUpdate = async (yearUpdate) => {
    setLoader(true)
    setIsActivated(false)
    setIsCompleted(false)
    setIsRating(false)
    setIsCreated(false)
    setSelectedDate(yearUpdate)
    let result =
      (await pprFormYear) &&
      pprFormYear.filter((year) => year.attributes.label === yearUpdate)
    if (result && result.length > 0) {
      setIsActivated(result[0].attributes.is_active)
      setIsCompleted(result[0].attributes.is_completed)
      setIsRating(result[0].attributes.start_rating)
      setIsCreated(true)
    } else {
      setIsActivated(false)
      setIsCompleted(false)
      setIsRating(false)
      setIsCreated(false)
    }
    setLoader(false)
  }
  const handleSave = async()=>{
    if(fields.active !== ''){
        setLoader(true)
        await updateAction.updatePPRFormYearByYear(selectedDate, fields.active)
        await fetch()
        setLoader(false)
    }
    else{
      setLoader(true)
      await fetch()
      setLoader(false)
    }
    if(fields.complete !== ''){
      setLoader(true)
      await updateAction.updatePPRFormCompleteByYear(selectedDate, fields.complete)
      await fetch()
      setLoader(false)
  }
  else{
    setLoader(true)
    await fetch()
    setLoader(false)
  }
  if(fields.rating !== ''){
    setLoader(true)
    await updateAction.updatePPRFormRatingByYear(selectedDate, fields.rating)
    await fetch()
    setLoader(false)
  }
  else{
    setLoader(true)
    await fetch()
    setLoader(false)
  }

  }
  const handleCreate = async()=>{
    setLoader(true)
    await createAction.createPPRFormYear(`${startDate.toString().split(' ', 4)[3]}`)
    await fetch()
    setLoader(false)
  }

  return (
    <>
      <button
        className='btn btn-sm btn-icon btn-primary'
        style={{whiteSpace: 'nowrap', width: '140px', marginTop: '20px', marginLeft: '10px'}}
        data-bs-toggle='modal'
        data-bs-target='#ConfigYearModal'
        onClick={()=> fetch()}
      >
        Create Year
      </button>
      
      <div className='modal fade' tabIndex={-1} id='ConfigYearModal'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>Create Year</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className="modal-body" style={{ display: 'flex' ,height: loader ? '350px' : '15%' }}>
            {loader ? 
            <div style={{ justifyContent: "flex-start" }}>
            <div className='overlay-wrapper p-5 position-absolute top-50 start-50 translate-middle'>
            <div
              className='spinner-border text-primary'
              style={{width: '13rem', height: '13rem'}}
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
            </div>
            </div> :
            
              <div>
                <Form.Group>
                  <Form.Label>Select a year:</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleDate}
                    dateFormat="yyyy"
                    showYearPicker
                    inline
                  />
                </Form.Group>
                <span>&nbsp;</span>
                <div style={{ justifyContent: "flex-start" }}>
                {isCreated ? <></> : <span ><h2 style={{color: "red"}}>Date is not created</h2></span> }
              </div>
              </div>
}
              {loader ? 
               <div className='table-responsive tableFixHead' style={{ overflow: 'auto', height: '85%', width: '80%', borderRadius: '5px', marginLeft: '2rem' }}>
              <div className='overlay-wrapper p-5 position-absolute top-50 start-50 translate-middle'>
              <div
                className='spinner-border text-primary'
                style={{width: '13rem', height: '13rem'}}
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
            </div>
            :
            <div className='table-responsive tableFixHead' style={{ overflow: 'auto', height: '85%', width: '80%', borderRadius: '5px', marginLeft: '2rem' }}>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
                <tr>
                  <th><h3><strong>Year</strong></h3></th>
                  <th><h3><strong>Active</strong></h3></th>
                  <th><h3><strong>Completed</strong></h3></th>
                  <th><h3><strong>Is Vice-President</strong></h3></th>
                </tr>
              </thead>
              <tbody>
                {pprFormYear && pprFormYear.map((item) => (
                  <tr key={item.id}>
                    <td>{item.attributes.label}</td>
                    <td>{item.attributes.is_active === true ? `Enabled` : `Disabled`}</td>
                    <td>{item.attributes.is_completed  === true ? `Enabled` : `Disabled`}</td>
                    <td>{item.attributes.is_vp  === true ? `Enabled` : `Disabled`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          }
            </div>
            <div className="modal-footer">
            <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
              Cancel
            </button>
            <button type='button' className='btn btn-primary btn-sm' onClick={handleCreate} disabled={isCreated}>
             Create
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='UpdateYearModal'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>Create Year</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className="modal-body" style={{ display: 'flex',height: loader ? '350px' : '15%' }}>
            {loader ? 
            <div style={{ justifyContent: "flex-start" }}>
            <div className='overlay-wrapper p-5 position-absolute top-50 start-50 translate-middle'>
            <div
              className='spinner-border text-primary'
              style={{width: '13rem', height: '13rem'}}
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
            </div>
            </div> :
            
              <div>
                <Form.Group>
                  <Form.Label><h1><strong>Update Year</strong></h1></Form.Label>
                  <br/>
                  <Form.Label><h3>{selectedDate}</h3></Form.Label>
                </Form.Group>
                <span>&nbsp;</span>
                <Form.Group>
                  <Form.Check type="switch" id="activate-switch" label="Activate" defaultChecked={isActivated} disabled={!isCreated} 
                  onChange={(e) => {
                    setFields({...fields, active: e.target.checked});
                    setIsActivated(e.target.checked);
                  }}
                  />
                </Form.Group>
                <span>&nbsp;</span>
                <Form.Group>
                  <Form.Check type="switch" id="completed-switch" label="Is Completed" defaultChecked={isCompleted} disabled={!isCreated}
                 onChange={(e) => {
                  setFields({...fields, complete: e.target.checked});
                  setIsCompleted(e.target.checked);
                }}/>
                </Form.Group>
                <span>&nbsp;</span>
                <Form.Group>
                  <Form.Check type="switch" id="rating-switch" label="Start Rating" defaultChecked={isRating} disabled={!isCreated}
                 onChange={(e) => {
                  setFields({...fields, rating: e.target.checked});
                  setIsRating(e.target.checked);
                }}/>
                </Form.Group>
              </div>
}
              {loader ? 
               <div className='table-responsive tableFixHead' style={{ overflow: 'auto', height: '85%', width: '80%', borderRadius: '5px', marginLeft: '2rem' }}>
              <div className='overlay-wrapper p-5 position-absolute top-50 start-50 translate-middle'>
              <div
                className='spinner-border text-primary'
                style={{width: '13rem', height: '13rem'}}
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
            </div>
            :
            <div className='table-responsive tableFixHead' style={{ overflow: 'auto', height: '85%', width: '80%', borderRadius: '5px', marginLeft: '2rem' }}>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
                <tr>
                  <th><h3><strong>Year</strong></h3></th>
                  <th><h3><strong>Active</strong></h3></th>
                  <th><h3><strong>Completed</strong></h3></th>
                  <th><h3><strong>Is Vice-President</strong></h3></th>
                  <th><h3><strong>Start Rating</strong></h3></th>
                </tr>
              </thead>
              <tbody>
                {pprFormYear && pprFormYear.map((item) => (
                  <tr key={item.id}>
                    <td>{item.attributes.label}</td>
                    <td>{item.attributes.is_active === true ? `Enabled` : `Disabled`}</td>
                    <td>{item.attributes.is_completed  === true ? `Enabled` : `Disabled`}</td>
                    <td>{item.attributes.is_vp  === true ? `Enabled` : `Disabled`}</td>
                    <td>{item.attributes.start_rating  === true ? `Enabled` : `Disabled`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          }
            </div>
            <div className="modal-footer">
            <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
              Cancel
            </button>
            <button type='button' className='btn btn-primary btn-sm' onClick={handleSave} disabled={!isCreated}>
             Save
              </button>
            </div>
          </div>
        </div>
      </div>

      
      
      <div className='table-responsive tableFixHead' style={{ overflow: 'auto', height: '85%', width: '100%', borderRadius: '5px', marginLeft: '2rem' }}>
      <table className='table table-rounded table-row-bordered border gy-7 gs-7 table-hover'
          style={{verticalAlign: 'middle', marginTop: "10px"}}>
        <thead>
          <tr>
            <th><h3><strong>Year</strong></h3></th>
            <th><h3><strong>Active</strong></h3></th>
            <th><h3><strong>Completed</strong></h3></th>
            <th><h3><strong>Is Vice-President</strong></h3></th>
            <th ><h3 style={{width:"200px"}}><strong>Start Rating</strong></h3></th>
            <th><h3><strong>Actions</strong></h3></th>
          </tr>
        </thead>
        <tbody>
          {pprFormYear && pprFormYear.map((item) => (
            <tr key={item.id}>
              <td>{item.attributes.label}</td>
              <td>{item.attributes.is_active === true ? `Enabled` : `Disabled`}</td>
              <td>{item.attributes.is_completed  === true ? `Enabled` : `Disabled`}</td>
              <td>{item.attributes.is_vp  === true ? `Enabled` : `Disabled`}</td>
              <td>{item.attributes.start_rating  === true ? `Enabled` : `Disabled`}</td>
              <td><button
              className='btn btn-sm btn-icon btn-primary'
              style={{whiteSpace: 'nowrap', width: '80px'}}
              data-bs-toggle='modal'
              data-bs-target='#UpdateYearModal'
              onClick={() =>handleDateUpdate(item.attributes.label)}
            >
              Update
            </button></td>
            </tr>
          ))}
        </tbody>
      </table>
</div>
    </>
  )
}
export default YearConfiguration
