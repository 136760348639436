/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React,{useEffect,useRef,useState} from 'react'
import {KTSVG} from '../../../helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useDispatch,useSelector} from 'react-redux'
import * as actionCreators from '../../../../app/redux/actions/index'
import * as mutation from '../../../../app/redux/actions/mutation'
import Swal from 'sweetalert2'
import {useNavigate,Link} from 'react-router-dom'
import {MixedWidget7} from './MixedWidget7'
import moment from 'moment'
type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget11: React.FC<Props> = ({className,chartColor,chartHeight}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.ppr.currentUser)
  const currentUserSupervisor = useSelector((state) => state.ppr.currentUserSupervisor)
  const currentUserOffice = useSelector((state) => state.ppr.currentUserOffice)
  const currentUserTitle = useSelector((state) => state.ppr.currentUserTitle)
  const currentYearPPRForm = useSelector((state) => state.ppr.currentYearPPRForm)
  const previousYearPPRForm = useSelector((state) => state.ppr.previousYearPPRForm)
  const pprFormCurrentYear = useSelector((state) => state.ppr.pprFormCurrentYear)
  const PPRFormYear = useSelector((state) => state.ppr.pprFormYear)
  const PPRYEAR = PPRFormYear && PPRFormYear.filter(year => year.attributes.is_active === true)[0].attributes.label
  const [startDate,setStartDate] = useState()
  const [reviewFromDate,setReviewFromDate] = useState(new Date(PPRYEAR,0,1,12))
  const [percentage,setPercentage] = useState(0)
  const [reviewToDate,setReviewToDate] = useState(new Date(PPRYEAR,11,31))
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const modalRef = useRef<HTMLButtonElement | null>(null)
  const closeRef = useRef<HTMLButtonElement | null>(null)
  const isInitialRender = useRef(true);

  const submit = async () => {
    btnRef.current?.setAttribute('data-kt-indicator','on')
    btnRef.current?.setAttribute('disabled',true)
    let body = {
      employee: currentUser,
      manager: currentUserSupervisor,
      office: currentUserOffice,
      employee_title: currentUserTitle,
      date_joined: startDate,
      date_review_start: moment(reviewFromDate).add(1,'days'),
      date_review_end: reviewToDate,
    }
    let result = await mutation.createPPRForm(body)
    btnRef.current?.removeAttribute('data-kt-indicator')
    btnRef.current?.removeAttribute('disabled')
    closeRef.current?.click()
    if(result === 500) {
      Swal.fire({
        text: 'Oops Something went wrong!',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
    } else {
      Swal.fire({
        text: 'Started PPR successfully!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if(result.isConfirmed) {
          navigate(`/profile/ppr/${currentUser.id}/${PPRYEAR}`)
        }
      })
    }
  }
  useEffect(() => {
    const fetch = async () => {
      await actionCreators.getPPRFormYear(dispatch)
    }
    fetch()
  },[])

  useEffect(() => {
    const fetchYear = async (year) => {
      if(year !== null) {
        await actionCreators.getCurrentUserPPRForms(PPRYEAR,dispatch)
        await actionCreators.getPreviousUserPPRForms(PPRYEAR,dispatch)
        await actionCreators.getPPRFormYearByYear(currentUser.id,year,dispatch)
        setReviewFromDate(new Date(year,0,1,12))
        setReviewToDate(new Date(PPRYEAR,11,31))

      }
    }
    fetchYear(PPRYEAR)
  },[PPRYEAR])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (currentYearPPRForm && currentYearPPRForm.length === 1) {
      let data = currentYearPPRForm[0];
      if (data) {
        if (data.attributes.employee_approval) {
          setPercentage(33);
        }
        if (data.attributes.manager_approval) {
          setPercentage(66);
        }
        if (data.attributes.president_approval) {
          setPercentage(100);
        }
      }
    }
  }, [currentYearPPRForm]);
  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        <div className='row'>
          <div className='col'>
            <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
              <div className='me-2'>
                {percentage === 100 ? <span className='fw-bold text-gray-800 d-block fs-3'>PPR {PPRYEAR} COMPLETED </span> :
                  <span className='fw-bold text-gray-800 d-block fs-3'>ON-GOING PPR</span>}
                <span className='text-gray-700 fw-semibold'>JAN 1, {PPRYEAR} - DEC 31, {PPRYEAR}</span>
              </div>
            </div>
            <div className='flex-grow-1 card-p pb-0'>
              <p className='fw-semibold '>
                {percentage === 100 ? <></> : <>
                  {currentYearPPRForm && currentYearPPRForm.length === 0
                    ? `You haven't started your PPR yet.`
                    : `You haven't finished your PPR yet.`}</>
                }

              </p>
              <div className='d-flex flex-stack flex-wrap'>
                {percentage === 100 ? <></> :
                  <div className='me-2'
                    style={{cursor: `${!currentUserSupervisor ? 'not-allowed' : 'pointer'}`}}>
                    {currentYearPPRForm && currentYearPPRForm.length === 0 ? (
                      <button
                        type='button'
                        className='btn btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        onClick={()=> setStartDate(new Date((previousYearPPRForm[0].attributes.date_joined).replace("-",",")))}
                      >
                        Start Now
                      </button>
                    ) : (
                      <Link
                        to={`/profile/ppr/${currentUser.id}/${PPRYEAR}`}
                        type='button'
                        className='btn btn-primary'
                      >
                        Resume Now
                      </Link>
                    )}
                  </div>
                }

              </div>
            </div>
          </div>
          <div className='col mt-auto mb-auto'>
            <MixedWidget7
              className='card-xl-stretch'
              chartColor='primary'
              chartHeight='200px'
              approvalPercentage={percentage}
            />
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1' ref={modalRef}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Employee Information</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body px-15'>
              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-left h-30px w-100'>
                  <p className='pt-5'>
                    {' '}
                    <strong> Name: </strong> {currentUser && currentUser.firstname}{' '}
                    {currentUser && currentUser.lastname}
                  </p>
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-left h-30px w-100'>
                  <p className='pt-5'>
                    {' '}
                    <strong> Job Title: </strong> {currentUserTitle ? currentUserTitle.name : 'N/A'}{' '}
                  </p>
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-left h-50px w-100'>
                  <p className='pt-5'>
                    {' '}
                    <strong> Reviewing Manager's Name: </strong>{' '}
                    {currentUserSupervisor ? currentUserSupervisor.firstname : 'Awaiting for your supervisor assignment'}{' '}
                  </p>
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-left h-30px w-100'>
                  <p className='pt-5'>
                    {' '}
                    <strong> Office Location: </strong>{' '}
                    {currentUserOffice ? currentUserOffice.name : 'N/A'}{' '}
                  </p>
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-left h-50px w-100px'>
                  <p className='pt-5'>
                    {' '}
                    <strong> Date Joined: </strong>{' '}
                  </p>
                </div>
                <div className='d-flex flex-center '>
                  <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-left h-50px w-100px'>
                  <p className='pt-5'>
                    {' '}
                    <strong> Review Period: </strong>{' '}
                  </p>
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className='d-flex flex-column-auto flex-center h-25px w-100px'>
                  <p className='pt-5'> From: </p>
                </div>
                <div className='d-flex flex-center '>
                  <DatePicker
                    selected={reviewFromDate}
                    onChange={(date: Date) => setReviewFromDate(date)}
                  />
                </div>
              </div>
              <div className='d-flex flex-row mt-2 '>
                <div className='d-flex flex-column-auto flex-center h-25px w-100px'>
                  <p className='pt-5'> To: </p>
                </div>
                <div className='d-flex flex-center '>
                  <DatePicker
                    selected={reviewToDate}
                    onChange={(date: Date) => setReviewToDate(date)}
                  />
                </div>
              </div>

            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                ref={closeRef}
              >
                Close
              </button>
              <button
                ref={btnRef}
                onClick={submit}
                type='button'
                className='btn btn-primary'
                id='kt_button_1'
              >
                <span className='indicator-label'>START PPR {PPRYEAR}</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MixedWidget11}
