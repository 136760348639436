import axios from 'axios'
import { createActivityLog } from './create'
import {updatePPRFormOne} from './update'
import moment from 'moment'
const apiServerUrl = process.env.REACT_APP_STRAPI_URL



// MUTATIONS
export const createPPRForm = (data) => {
    return axios
      .post(`${apiServerUrl}/api/ppr-forms`, {
        data: data,
      })
      .then(async (response) => {
        await createActivityLog({
          part: null,
          action: 'created ppr form',
          by: data.employee,
          data: response.data.data,
          owner: data.employee,
          ppr_form: response.data.data.id,
          ppr_year: data.date_review_start,
        })
        return await axios.get(`${apiServerUrl}/api/ppr-forms?filters[employee][id][$eq]=${data.employee.id}&filters[$and][0][date_review_start][$gt]=${moment(data.date_review_start).year()-1}-01-01&filters[$and][1][date_review_start][$lt]=${moment(data.date_review_start).year()-1}-12-31`).then(async (response2) => {
          return await axios.get(`${apiServerUrl}/api/ppr-form-sixes?filters[ppr_form][id][$eq]=${response2.data.data[0].id}&populate=*`).then((response3)=>{
            if(response3.data.data.length > 0){
            let rows = Object.keys(response3.data.data)
            return new Promise(async (resolve, reject) => {
              let promises = []
              rows.forEach(async (row) => {
                  promises.push(
                    new Promise(async (resolve, reject) => {
                      let body = {
                        ppr_form: response.data.data.id,
                        weight: parseInt(response3.data.data[row].attributes.weight),
                        duty_responsibility: response3.data.data[row].attributes.duties_and_responsibilities,
                        key_goals: response3.data.data[row].attributes.key_goals,
                      }
                      await axios.post(`${apiServerUrl}/api/ppr-form-ones`, {
                        data: body,
                      })
                      resolve()
                    })
                  )
              })
              Promise.all(promises).then(async () => {
                resolve('done')
              })
            })
          }
          })
        })

      })
      .catch(function (error) {
        if (error.response) {
          return error.response.status
        }
      })
  }
  export const setAllSupervisedStaff = (data) => {
    return {
      type: 'SET_ALL_SUPERVISED_STAFF',
      data: data,
    }
  }
  export const setSupervisedStaff = (data) => {
    return {
      type: 'SET_SUPERVISED_STAFF',
      data: data,
    }
  }
  
  export const setCurrentUser = (data) => {
    return {
      type: 'SET_CURRENT_USER',
      data: data,
    }
  }
  
  export const setCurrentUserSupervisor = (data) => {
    return {
      type: 'SET_CURRENT_USER_SUPERVISOR',
      data: data,
    }
  }
  
  export const setCurrentUserOffice = (data) => {
    return {
      type: 'SET_CURRENT_USER_OFFICE',
      data: data,
    }
  }
  
  export const setNotifications = (data) => {
    return {
      type: 'SET_NOTIFICATIONS',
      data: data,
    }
  }
  export const setCurrentUserTitle = (data) => {
    return {
      type: 'SET_CURRENT_USER_TITLE',
      data: data,
    }
  }
  
  export const setCurrentYearPPRForm = (data) => {
    return {
      type: 'SET_CURRENT_YEAR_PPR_FORM',
      data: data,
    }
  }
  export const setPreviousYearPPRForm = (data) => {
    return {
      type: 'SET_PREVIOUS_YEAR_PPR_FORM',
      data: data,
    }
  }
  
  export const setCurrentYearPPRFormByYear = (data) => {
    return {
      type: 'SET_CURRENT_YEAR_PPR_FORM_BY_YEAR',
      data: data,
    }
  }
  
  export const setAllCurrentYearPPRForm = (data) => {
    return {
      type: 'SET_ALL_CURRENT_YEAR_PPR_FORM',
      data: data,
    }
  }
  
  export const setPart1Result = (data) => {
    return {
      type: 'SET_PART1_RESULT',
      data: data,
    }
  }
  
  export const setPart1AResult = (data) => {
    return {
      type: 'SET_PART1A_RESULT',
      data: data,
    }
  }
  
  export const setPart2Result = (data) => {
    return {
      type: 'SET_PART2_RESULT',
      data: data,
    }
  }
  
  export const setPart2AResult = (data) => {
    return {
      type: 'SET_PART2A_RESULT',
      data: data,
    }
  }
  
  export const setSelectedPPRForm = (data) => {
    return {
      type: 'SET_SELECTED_PPR_FORM',
      data: data,
    }
  }
  
  export const setPart5Result = (data) => {
    return {
      type: 'SET_PART5_RESULT',
      data: data,
    }
  }
  export const setPart6Result = (data) => {
    return {
      type: 'SET_PART6_RESULT',
      data: data,
    }
  }
  
  export const setCurrentPresident = (data) => {
    return {
      type: 'SET_CURRENT_PRESIDENT',
      data: data,
    }
  }
  
  export const setActivityLogs = (data) => {
    return {
      type: 'SET_ACTIVITY_LOGS',
      data: data,
    }
  }
  
  export const setAllUsers = (data) => {
    return {
      type: 'SET_ALL_USERS',
      data: data,
    }
  }
  
  export const setAllJobTitles = (data) => {
    return {
      type: 'SET_ALL_JOB_TITLES',
      data: data,
    }
  }
  
  export const setAllOffices = (data) => {
    return {
      type: 'SET_ALL_OFFICES',
      data: data,
    }
  }
  export const setPPRFormYear = (data) => {
    return {
      type: 'SET_PPR_FORM_YEAR',
      data: data,
    }
  }
  export const setPPRFormYearByYear = (data) => {
    return {
      type: 'SET_PPR_FORM_YEAR_BY_YEAR',
      data: data,
    }
  }
  export const setUserAssignments = (data) => {
    return {
      type: 'SET_USER_ASSIGNMENTS',
      data: data
    }
  }
  // MUTATIONS
  