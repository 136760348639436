/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import {KTSVG} from '../../../../_metronic/helpers'
import {FC, useEffect} from 'react'
import {useSelector} from 'react-redux'
type Props = {
  setFiltersAssignment: Function
  filtersAssignment : Object
  isFiltering : Boolean
  setIsFiltering : Function
  getAdminListAssignment: Function
}
const Search: FC<Props> = ({isApplied,setFiltersAssignment,filtersAssignment, setIsFiltering,getAdminListAssignment,isFiltering}) => {
  const handleChange = (val,key) => {
    setFiltersAssignment((old) => ({
      ...old,
      [key]: val,
    }))
  }
  useEffect(() => {
    if(filtersAssignment.newSupervisorName !== '' || filtersAssignment.newSupervisorEmail !== '' || filtersAssignment.newSupervisorExist !== '' || filtersAssignment.Search !== '') {
      setIsFiltering(true)
    }
    else {
      setIsFiltering(false)
    }
  },[filtersAssignment])
  return (
    <>
      <div className='d-flex align-items-center position-relative my-1' style={{paddingTop: "14px"}}>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search user'
          onChange={(e) => {
            handleChange(e.target.value,'Search')
          }}
        />
        <div style={{paddingLeft: '10px'}}>
          <button
            type='button'
            className='btn btn-light-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={() => {getAdminListAssignment(1,isFiltering)}}
          >
            Search
          </button>
        </div>
      </div>
    </>
  )
}

export default Search
