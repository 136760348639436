//@ts-nocheck
import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import JwtService from '../../../services/JwtService'
import Auth0 from 'auth0-js'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function verify(id_token:string) {
  const userInfo = JwtService.decode(id_token)
  return {
    data: {
      jwt: userInfo.token,
      user: userInfo.user_data
    }
  }
}
export function logout() {
  JwtService.destroyAPIToken()
  JwtService.destroyToken()
  JwtService.destroyUserID()
  window.location.href = `${process.env.REACT_APP_API_LOGOUT_URL}&redirect_uri=${process.env.REACT_APP_URL}`
}
// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
