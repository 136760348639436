//@ts-nocheck
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl, KTSVG} from '../../../../_metronic/helpers'
import * as actionCreators from '../../../redux/actions/index'
import * as updateAction from '../../../redux/actions/update'
import moment from 'moment'
import './styles.css'
import _ from 'lodash'

type Props = {
  users: Array
  setUsers: Function
  fetching: Object
  currentPage: number
  getAdminList: Function
  isFiltering: boolean
}

const UserList: FC<Props> = ({
  users,
  setUsers,
  fetching,
  getAdminList,
  isFiltering,
  currentPage,
}) => {
  const [selectedData, setSelectedData] = useState()
  const [option, setOption] = useState('0')
  const [isFetching, setFetching] = useState(false)
  const offices = useSelector((state) => state.ppr.offices)
  const jobTitles = useSelector((state) => state.ppr.jobTitles)
  const userList = useSelector((state) => state.ppr.userList)
  const [currentSupervisor, setCurrentSupervisor] = useState()
  const [currentOffice, setCurrentOffice] = useState()
  const [currentTitle, setCurrentTitle] = useState()
  const [currentStatus, setCurrentStatus] = useState()
  const [changeTo, setChangeTo] = useState('0')
  const [history, setHistory] = useState()
  const [oldHistorySupervisor, setOldHistorySupervisor] = useState([])
  const [oldHistoryOffice, setOldHistoryOffice] = useState([])
  const [oldHistoryTitle, setOldHistoryTitle] = useState([])
  const update = async () => {
    setFetching(true)
    let result = await updateAction.updateAssignments(selectedData.id, option, changeTo)
    setFetching(false)
    setChangeTo('0')
    getAdminList(currentPage, isFiltering)
    if (option === '1') {
      if (typeof result !== 'string') {
        setHistory((old) => [result, ...old])
        setOldHistorySupervisor((old) => [result, ...old])
        setCurrentSupervisor(result.new_supervised_by)
      } else {
        setHistory([])
        setCurrentSupervisor(result)
      }
    } else if (option === '2') {
      if (typeof result !== 'string') {
        setHistory((old) => [result, ...old])
        setOldHistoryOffice((old) => [result, ...old])
        setCurrentOffice(result.new_office)
      } else {
        setHistory([])
        setCurrentOffice(result)
      }
    } else if (option === '3') {
      if (typeof result !== 'string') {
        setHistory((old) => [result, ...old])
        setOldHistoryTitle((old) => [result, ...old])
        setCurrentTitle(result.new_job_title)
      } else {
        setHistory([])
        setCurrentTitle(result)
      }
    } else if (option === '4') {
      if (typeof result !== 'string') {
        setCurrentStatus(result.data.blocked ? 'Inactive' : 'Active')
      } else {
        setCurrentStatus(result.data.blocked ? 'Inactive' : 'Active')
      }
    }
  }
  const sortHistoryList = (data) => {
    return _.orderBy(data, [(obj) => new Date(obj.date_ended)], ['desc'])
  }
  useEffect(() => {
    setHistory()
    setOption('0')
    setOldHistoryOffice([])
    setOldHistorySupervisor([])
    setOldHistoryTitle([])
    if (selectedData) {
      if (selectedData.job_assignments.length > 0) {
        setCurrentTitle(selectedData.job_assignments[0].job_title? selectedData.job_assignments[0].job_title.name : 'N/A')
      } else {
        setCurrentTitle('N/A')
      }
      if (selectedData.office_assignments.length > 0) {
        setCurrentOffice(selectedData.office_assignments[0].office.name)
      } else {
        setCurrentOffice('N/A')
      }
      if (selectedData.supervisors.length > 0) {
        setCurrentSupervisor(selectedData.supervisors[0].supervised_by.full_name)
      } else {
        setCurrentSupervisor('N/A')
      }
      if(selectedData.blocked){
        setCurrentStatus('Inactive')
      }else{
        setCurrentStatus('Active')
      }
    }
  }, [selectedData])
  useEffect(() => {
    if (selectedData && option !== '0') {
      if (option === '1') {
        if (selectedData.supervisors.length > 0) {
          setHistory(
            selectedData.supervisors[0].history && selectedData.supervisors[0].history.length
              ? sortHistoryList([...oldHistorySupervisor, ...selectedData.supervisors[0].history])
              : [...oldHistorySupervisor]
          )
        } else {
          setHistory([...oldHistorySupervisor])
        }
      } else if (option === '2') {
        if (selectedData.office_assignments.length > 0) {
          setHistory(
            selectedData.office_assignments[0].history &&
              selectedData.office_assignments[0].history.length > 0
              ? sortHistoryList([
                  ...oldHistoryOffice,
                  ...selectedData.office_assignments[0].history,
                ])
              : [...oldHistoryOffice]
          )
        } else {
          setHistory([...oldHistoryOffice])
        }
      } else if (option === '3') {
        if (selectedData.job_assignments.length > 0) {
          setHistory(
            selectedData.job_assignments[0].history &&
              selectedData.job_assignments[0].history.length > 0
              ? sortHistoryList([...oldHistoryTitle, ...selectedData.job_assignments[0].history])
              : [...oldHistoryTitle]
          )
        } else {
          setHistory([...oldHistoryTitle])
        }
      }
    }
  }, [option])
  return (
    <>
      <div className='table-responsive'>
        <table
          className='table table-rounded table-row-bordered border gy-7 gs-7 table-hover'
          style={{verticalAlign: 'middle'}}
        >
          <thead>
            <tr className='fw-bolder fs-6 text-gray-800'>
              <th style={{width: '5%'}} className='px-8'>
                <input className='form-check-input' type='checkbox' />
              </th>
              <th style={{width: '25%'}}>Name</th>
              <th style={{width: '20%'}}>Supervisor</th>
              <th style={{width: '25%'}}>Position</th>
              <th style={{width: '25%'}}>Office</th>
              <th style={{width: '25%'}}>Status</th>
            </tr>
          </thead>
          {!fetching ? (
            users.length > 0 ? (
              <tbody>
                {users &&
                  users.map((user, index) => (
                    <tr key={index}>
                      <td className='p-8'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          defaultValue={user.selected}
                        />
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <a href='#'>
                              <div className='symbol-label'>
                                <img
                                  src={
                                    user.image
                                      ? user.image
                                      : toAbsoluteUrl('/media/male-placeholder-image.jpeg')
                                  }
                                  alt=''
                                  className='w-100'
                                />
                              </div>
                            </a>
                          </div>
                          <div className='d-flex flex-column'>
                            <a
                              href='#'
                              className='text-gray-800 text-hover-primary mb-1'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_1'
                              onClick={() => {
                                setSelectedData(user)
                              }}
                            >
                              {user.full_name}
                            </a>
                            <span>{user.email}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {user.supervisors && user.supervisors.length > 0
                          ? user.supervisors[0].supervised_by
                            ? user.supervisors[0].supervised_by.full_name
                            : 'N/A'
                          : 'N/A'}
                      </td>
                      <td>
                        {user.job_assignments && user.job_assignments.length > 0
                          ? user.job_assignments[0].job_title ? user.job_assignments[0].job_title.name : 'N/A'
                          : 'N/A'}
                      </td>
                      <td>
                        {user.office_assignments && user.office_assignments.length > 0
                          ? user.office_assignments[0].office ? user.office_assignments[0].office.name : 'N/A'
                          : 'N/A'}
                      </td>
                      <td>
                        {user.blocked ? (
                          <span className='badge badge-light-danger'>Inactive</span>
                        ) : (
                          <span className='badge badge-light-success'>Active</span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td rowSpan={5}>No records found.</td>
                </tr>
              </tbody>
            )
          ) : (
            <tbody>
              <tr>
                <td rowSpan={7} style={{height: '500px'}}>
                  <div
                    className='overlay-wrapper p-5 position-absolute start-50 translate-middle'
                    style={{top: '60%'}}
                  >
                    <div
                      className='spinner-border text-primary'
                      style={{width: '13rem', height: '13rem'}}
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content' style={{height: '50vh'}}>
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>Update User</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div
              className={`modal-body ${isFetching ? 'overlay overlay-block' : ''}`}
              style={{height: '85%'}}
            >
              {isFetching ? (
                <>
                  <div className='overlay-layer bg-dark bg-opacity-5'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className='row h-100'>
                <div className='col-5'>
                  {selectedData ? (
                    <>
                      <div className='row px-3 pb-5'>
                        <label className='form-label'>
                          <b>User:</b> {selectedData.full_name}
                        </label>
                      </div>
                      <div className='row px-3 pb-5'>
                        <label className='form-label'>
                          <b>Field Type</b>
                        </label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          value={option}
                          onChange={(e) => {
                            setOption(e.target.value)
                          }}
                        >
                          <option value='0'>Select data to update</option>
                          <option value='1'>Supervisor</option>
                          <option value='2'>Office</option>
                          <option value='3'>Title</option>
                          <option value='4'>Status</option>
                        </select>
                      </div>
                      <div className='row px-3 pb-5'>
                        <label className='form-label'>
                          <b>
                            Current{' '}
                            {option === '1'
                              ? 'Supervisor'
                              : option === '2'
                              ? 'Office'
                              : option === '3'
                              ? 'Job Title'
                              : option === '4'
                              ? 'Status'
                              : ''}
                          </b>
                        </label>
                        <select className='form-select' disabled>
                          {option === '0' && <option value='0'>Field type not selected</option>}
                          {option === '1' && <option value='0'>{currentSupervisor}</option>}
                          {option === '2' && <option value='0'>{currentOffice}</option>}
                          {option === '3' && <option value='0'>{currentTitle}</option>}
                          {option === '4' && <option value='0'>{currentStatus}</option>}
                        </select>
                      </div>
                      <div className='row px-3'>
                        <label className='form-label'>
                          <b>Change To</b>
                        </label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          value={changeTo}
                          onChange={(e) => {
                            setChangeTo(e.target.value)
                          }}
                        >
                          {option === '0' && <option value='0'>Field type not selected</option>}
                          {option === '1' && (
                            <>
                              <option value='0'> -- Select -- </option>
                              {userList.map((user, index) => {
                                if (user.full_name !== currentSupervisor) {
                                  return (
                                    <option value={user.id} key={index}>
                                      {user.full_name}
                                    </option>
                                  )
                                }
                              })}
                            </>
                          )}
                          {option === '2' && (
                            <>
                              <option value='0'> -- Select -- </option>
                              {offices.map((office, index) => {
                                if (office.attributes.name !== currentOffice) {
                                  return (
                                    <option value={office.id} key={index}>
                                      {office.attributes.name}
                                    </option>
                                  )
                                }
                              })}
                            </>
                          )}
                          {option === '3' && (
                            <>
                              <option value='0'> -- Select -- </option>
                              {jobTitles.map((title, index) => {
                                if (title.attributes.name !== currentTitle) {
                                  return (
                                    <option value={title.id} key={index}>
                                      {title.attributes.name}
                                    </option>
                                  )
                                }
                              })}
                            </>
                          )}
                           {option === '4' && (
                            <>
                              <option value='0'> -- Select -- </option>
                              <option value='1'>Active</option>
                              <option value='2'>Inactive</option>
                            </>
                          )}
                        </select>
                      </div>
                      <button
                        type='button'
                        className='btn btn-primary mt-5'
                        onClick={() => {
                          update()
                        }}
                        disabled={changeTo === '0'}
                      >
                        Update
                      </button>
                      <button
                        type='button'
                        className='btn btn-danger mt-5'
                        disabled={changeTo === '0'}
                        style={{marginLeft: '250px'}}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='col-7 h-100'>
                  <label className='form-label fs-2 mb-5' style={{height: '5%'}}>
                    <b>History</b>
                  </label>
                  {option === '0' ? (
                    <>
                      <div className='h-100 w-100'>
                        <div className='row m-auto w-100 h-100'>
                          <label className='form-label fs-2x m-auto' style={{textAlign: 'center'}}>
                            <b>Select a Field Type</b>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className='table-responsive tableFixHead'
                        style={{overflow: 'auto', height: '85%', borderRadius: '5px'}}
                      >
                        <table className='table table-striped gy-7 gs-7'>
                          <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                              <th style={{backgroundColor: '#009ef7', color: '#FFFFFF'}}>
                                Previous{' '}
                                {option === '1'
                                  ? 'Supervisors'
                                  : option === '2'
                                  ? 'Offices'
                                  : 'Job Titles'}
                              </th>
                              <th style={{backgroundColor: '#009ef7', color: '#FFFFFF'}}>
                                Date Started
                              </th>
                              <th style={{backgroundColor: '#009ef7', color: '#FFFFFF'}}>
                                Date Ended
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {history &&
                              history.length > 0 &&
                              history.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    {option === '1'
                                      ? data.supervised_by
                                      : option === '3'
                                      ? data.job_title
                                      : option === '2'
                                      ? data.office
                                      : ''}
                                  </td>
                                  <td>{moment(data.date_started).format('LLL')}</td>
                                  <td>{moment(data.date_ended).format('LLL')}</td>
                                </tr>
                              ))}
                            {!history ||
                              (history.length === 0 && (
                                <tr>
                                  <td>No recent assignments/supervision</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserList
