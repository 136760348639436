//@ts-nocheck
import {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useSelector} from 'react-redux'
import * as actionCreators from '../../../redux/actions/create'
import Swal from 'sweetalert2'
import {Modal} from 'bootstrap'
const animatedComponents = makeAnimated()
type CreateEntryProps = {
  setRefetch: Function
}

const CreateEntry: FC<CreateEntryProps> = ({setRefetch}) => {
  const myModalRef = useRef()
  const [myModal, setMyModal] = useState(null)
  const [currentStage, setCurrentStage] = useState(1)
  const [isSupervisor, setIsSupervisor] = useState(false)
  const offices = useSelector((state) => state.ppr.offices)
  const jobTitles = useSelector((state) => state.ppr.jobTitles)
  const userList = useSelector((state) => state.ppr.userList)
  const options = userList
    ? userList.map((user) => ({
        value: user.id,
        label: user.full_name,
        email: user.email,
        supervisor_id:
          user.supervisors && user.supervisors.length > 0
            ? user.supervisors[0].supervised_by.id
            : null,
        supervisor:
          user.supervisors && user.supervisors.length > 0
            ? user.supervisors[0].supervised_by.full_name
            : null,
        job_title:
          user.job_assignments && user.job_assignments.length > 0
            ? user.job_assignments[0].job_title ? user.job_assignments[0].job_title.id : null
            : null,
        office_assignment:
          user.office_assignments && user.office_assignments.length > 0
            ? user.office_assignments[0].office.id
            : null,
      }))
    : []
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    job: '',
    office: '',
    supervisor: '',
    supervisees: [],
    customJobTitle: '',
  })

  function handleShow() {
    if (!myModal) {
      new Modal(myModalRef.current).show()
    } else {
      myModal.show()
    }
  }

  function handleHide() {
    if (myModal) {
      myModal.hide()
    } 
  }

  const handleRemove = (index) => {
    let supervisees = form.supervisees
    supervisees.splice(index, 1)
    setForm((old) => ({
      ...old,
      ['supervisees']: supervisees,
    }))
  }
  const onSubmit = async (event) => {
    event.preventDefault()
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })
    if (currentStage === 1 && !isSupervisor) {
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Create',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (res) => {
          if (res.isConfirmed) {
            await actionCreators.createUserAssignments(form).then(() => {
              setRefetch(true)
              handleHide()
            })
          }
        })
    } else if (currentStage === 2 && isSupervisor) {
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Create',
          reverseButtons: true,
        })
        .then(async (res) => {
          if (res.isConfirmed) {
            await actionCreators.createUserAssignments(form).then(() => {
              setRefetch(true)
              handleHide()
            })
          }
        })
    } else if (currentStage === 1 && isSupervisor) {
      setCurrentStage(2)
    }
  }
  const prevStage = () => {
    setCurrentStage(1)
  }
  useEffect(() => {
    setMyModal(new Modal(myModalRef.current))
  }, [])
  const handleChange = (val, field) => {
    setForm((old) => ({
      ...old,
      [field]: val,
    }))
  }
  useEffect(() => {
    if (!isSupervisor) {
      handleChange([], 'supervisees')
    }
  }, [isSupervisor])
  return (
    <>
      <button
        className='btn btn-sm btn-icon btn-primary'
        style={{whiteSpace: 'nowrap', width: '120px', marginTop: '20px', marginLeft: '10px'}}
        onClick={handleShow}
      >
        Create Entry
      </button>

      <div className='modal fade text-start' ref={myModalRef} tabIndex={-1} id='createEntryModal'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>
                Create Entry {'🡪'} {currentStage === 1 ? 'User Details' : 'User Assignments'}
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleHide}
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <form onSubmit={onSubmit}>
                {currentStage === 1 ? (
                  <>
                    <div className='row'>
                      <div className='col-3'>
                        <label className='form-label required'>First Name</label>
                        <input
                          type='text'
                          required
                          className='form-control'
                          value={form.firstname}
                          onChange={(e) => {
                            handleChange(e.target.value, 'firstname')
                          }}
                        />
                      </div>
                      <div className='col-3'>
                        <label className='form-label required'>Last Name</label>
                        <input
                          type='text'
                          className='form-control'
                          value={form.lastname}
                          required
                          onChange={(e) => {
                            handleChange(e.target.value, 'lastname')
                          }}
                        />
                      </div>
                      <div className='col-6'>
                        <label className='form-label required'>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          value={form.email}
                          required
                          onChange={(e) => {
                            handleChange(e.target.value, 'email')
                          }}
                        />
                      </div>
                    </div>
                    <div className='row pt-5'>
                      <div className='col-6'>
                        <label className='form-label required'>Assign Job Title</label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          required
                          value={form.job}
                          onChange={(e) => {
                            handleChange(e.target.value, 'job')
                          }}
                        >
                          <option value=''> - Select - </option>
                          {jobTitles &&
                            jobTitles.map((title, index) => {
                              return (
                                <option value={title.id} key={index}>
                                  {title.attributes.name}
                                </option>
                              )
                            })}
                            <option value="Custom Job Title">Custom Job Title</option>
                        </select>
                        {form.job === "Custom Job Title" && (
                            <input
                              type="text"
                              className="form-control mt-2"
                              placeholder="Enter custom job title"
                              value={form.customJobTitle}
                              onChange={(e) => handleChange(e.target.value, 'customJobTitle')}
                            />
                          )}
                      </div>
                      <div className='col-6'>
                        <label className='form-label required'>Assign Office</label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          required
                          value={form.office}
                          onChange={(e) => {
                            handleChange(e.target.value, 'office')
                          }}
                        >
                          <option value=''> - Select - </option>
                          {offices &&
                            offices.map((office, index) => {
                              return (
                                <option value={office.id} key={index}>
                                  {office.attributes.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className='row pt-5'>
                      <div className='col-9'>
                        <label className='form-label required'>Select a Supervisor</label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          value={form.supervisor}
                          required
                          onChange={(e) => {
                            handleChange(e.target.value, 'supervisor')
                          }}
                        >
                          <option value=''> - Select - </option>
                          {userList &&
                            userList.map((user, index) => {
                              return (
                                <option value={user.id} key={index}>
                                  {user.full_name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className='col-3'>
                        <label className='form-label pb-7'>{''}</label>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexSwitchDefault'
                            checked={isSupervisor}
                            onChange={() => {
                              setIsSupervisor(!isSupervisor)
                            }}
                          />
                          <label className='form-check-label'>Supervisor</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {isSupervisor && (
                      <div className='row'>
                        <div className='col'>
                          <label className='form-label'>Select Supervisees</label>
                          <Select
                            style={{border: '1px solid #E4E6EF'}}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            defaultValue={form.supervisees}
                            options={options}
                            onChange={(e) => {
                              handleChange(e, 'supervisees')
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isSupervisor && currentStage === 2 ? (
                  <>
                    <table className='table table-row-bordered border table-rounded table-row-gray-300 gy-7 px-5 mt-5'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>Supervisee</th>
                          <th>Current Supervisor</th>
                          <th>New Supervisor</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.supervisees.length > 0 ? (
                          form.supervisees.map((supervisee, index) => (
                            <tr key={index}>
                              <td>{supervisee.label}</td>
                              <td>{supervisee.supervisor ? supervisee.supervisor : 'N/A'}</td>
                              <td>
                                {form.firstname} {form.lastname}
                              </td>
                              <td>
                                <button
                                  className='btn btn-sm btn-danger'
                                  onClick={() => {
                                    handleRemove(index)
                                  }}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td rowSpan={4}>No supervisee selected.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className='row'>
                      <span>
                        Warning: Supervisees with existing supervisor assigned to them will be
                        replaced upon user login.
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className='row pt-5'>
                  <div className='col text-center'>
                    <button
                      className='btn btn-sm btn-secondary'
                      type='button'
                      onClick={prevStage}
                      disabled={currentStage === 1}
                    >
                      Previous
                    </button>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      style={{marginLeft: '15px'}}
                    >
                      {!isSupervisor ? 'Submit' : currentStage === 1 ? 'Next' : 'Submit'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateEntry
