/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import {FC, useEffect, useState} from 'react'
import {Filters} from './components/Filters'
import UserList from './components/UserList'
import UserAssignments from './components/UserAssignments'
import CreateEntry from './components/createEntry'
import YearConfiguration from './components/YearConfiguration'
import UpdateEntry from './components/updateEntry'
import * as actionCreators from '../../../app/redux/actions/index'
import * as updateAction from '../../../app/redux/actions/update'
import * as createAction from '../../../app/redux/actions/create'
import {useDispatch, useSelector} from 'react-redux'
import {Pagination} from './components/Pagination'
import Search from './components/Search'
import {KTSVG} from '../../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import './styles.css'
const AdminPage: FC = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState()
  const [pages, setPages] = useState([])
  const [startDate, setStartDate] = useState()
  const [fetching, setFetching] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [filters, setFilters] = useState({Supervisor: '', Position: '', Office: '', Search: ''})
  const [filtersAssignment, setFiltersAssignment] = useState({newSupervisorName: '', newSupervisorEmail: '', newSupervisorExist: '', Search: ''})
  const [currentPage, setCurrentPage] = useState(1)
  const [users, setUsers] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)
  const [isActivated, setIsActivated] = useState()
  const [loader, setLoader] = useState(false)
  const userList = useSelector((state) => state.ppr.userList)
  const userAssignments = useSelector((state) => state.ppr.userAssignments)
  const [userAssignmentsList, setUserAssignmentsList] = useState(userAssignments)
  const [assignmentPage, setAssignmentPage] = useState([])
  const [currentAssignmentPage, setCurrentAssignmentPage] = useState(1)
  const pprFormYear = useSelector((state) => state.ppr.pprFormYear)
  const fetchUsers = async () => {
    await actionCreators.getUsers(dispatch)
    await actionCreators.getJobTitles(dispatch)
    await actionCreators.getOffices(dispatch)
    await actionCreators.getCurrentUserPPRForms(new Date().getFullYear().toString(),dispatch)
  }
  useEffect(() => {
    if (users !== null) {
      if (users.length === 0) {
        fetchUsers()
        getAdminList(currentPage, isFiltering)
      }
    }
    //eslint-disable-next-line
  }, [users])
  useEffect(() => {
    getAdminList(currentPage, isFiltering)
    //eslint-disable-next-line
  }, [currentPage])

  useEffect(() => {
    getAdminListAssignment(currentAssignmentPage, isFiltering)
    //eslint-disable-next-line
  }, [currentAssignmentPage])


  const getAdminList = async (page, Filtering) => {
    setFetching(true)
    if (page === 1) {
      if (isFiltering) {
        let newpage = page
        let result = await actionCreators.getAdminUser(filters, newpage, Filtering)
        setCurrentPage(1)
        setPages(1)
        setUsers(result)
        setFetching(false)
      } else {
        let newpage = page
        let result = await actionCreators.getAdminUser(filters, newpage, Filtering)
        let total = await actionCreators.getTotal()
        setPages(Math.floor(total.length / 10) + 1)
        setUsers(result)
        setFetching(false)
      }
    } else {
      if (isFiltering) {
        let newpage = page * 10 - 10 + 1
        let result = await actionCreators.getAdminUser(filters, newpage)
        setPages(1)
        setUsers(result)
        setFetching(false)
      } else {
        let newpage = page * 10 - 10 + 1
        let result = await actionCreators.getAdminUser(filters, newpage)
        let total = await actionCreators.getTotal()
        setPages(Math.floor(total.length / 10) + 1)
        setUsers(result)
        setFetching(false)
      }
    }
  }

  const getAdminListAssignment = async (page, Filtering) => {
    setRefetch(true)
    if (page === 1) {
      if (isFiltering) {
        let newpage = page
        let result = await actionCreators.getAdminUserAssignment(filtersAssignment, newpage, Filtering)
        setCurrentAssignmentPage(1)
        setAssignmentPage(1)
        setUserAssignmentsList(result)
        setRefetch(false)
      } else {
        let newpage = page
        let result = await actionCreators.getAdminUserAssignment(filtersAssignment, newpage, Filtering)
        let total = await actionCreators.getTotalAssignment()
        setAssignmentPage(Math.floor(total.length / 5) + 1)
        setUserAssignmentsList(result)
        setRefetch(false)
      }
    } else {
      if (isFiltering) {
        let newpage = page + 1 - 1
        let result = await actionCreators.getAdminUserAssignment(filtersAssignment, newpage)
        setAssignmentPage(1)
        setUserAssignmentsList(result)
        setRefetch(false)
      } else {
        let newpage = page + 1 - 1
        let result = await actionCreators.getAdminUserAssignment(filtersAssignment, newpage)
        let total = await actionCreators.getTotalAssignment()
        setAssignmentPage(Math.floor(total.length / 5) + 1)
        setUserAssignmentsList(result)
        setRefetch(false)
      }
    }
  }
  const fetch = async () => {
    await actionCreators.getPPRFormYear(dispatch)
  }
  useEffect(() => {
    fetch()
  }, [])
  const handleDate = async (e) => {
    setStartDate(e)
    let result =
      (await pprFormYear) &&
      pprFormYear.filter((year) => year.attributes.label === `${e.toString().split(' ', 4)[3]}`)
    if (result && result.length > 0) {
      setIsActivated(result[0])
    } else {
      setIsActivated(false)
    }
  }
  const handleClick = async (bool, type) => {
    if (type === 'is_active') {
      if (isActivated) {
        if (startDate) {
          setLoader(true)
          await updateAction.updatePPRFormYearByYear(isActivated.attributes.label, bool)
          await fetch()
          let result =
            (await pprFormYear) &&
            pprFormYear.filter(
              (year) => year.attributes.label === `${startDate.toString().split(' ', 4)[3]}`
            )
          setIsActivated(result[0])
          setStartDate()
          setLoader(false)
        }
      } else {
        alert('please select date')
      }
    }
    if (type === 'is_completed') {
      if (isActivated) {
        if (startDate) {
          setLoader(true)
          await updateAction.updatePPRFormCompleteByYear(isActivated.attributes.label, bool)
          await fetch()
          let result =
            (await pprFormYear) &&
            pprFormYear.filter(
              (year) => year.attributes.label === `${startDate.toString().split(' ', 4)[3]}`
            )
          setIsActivated(result[0])
          setStartDate()
          setLoader(false)
        }
      } else {
        alert('please select date')
      }
    }
  }
  const createUser = async (username, password, email, fullname) => {
    createAction.createUser(username, password, email, fullname)
  }

  return (
    <>
      <div className='card p-5'>
        <ul className='nav nav-tabs fs-6'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Users
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              User Assignments
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
              PPR Year Management
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <div className='card-header p-0' style={{borderBottom: '0px'}}>
              <Filters
                setSearch={setSearch}
                setFilters={setFilters}
                getAdminList={getAdminList}
                filters={filters}
                setIsFiltering={setIsFiltering}
                isFiltering={isFiltering}
              />
            </div>
            <div className='card-body p-0'>
              <UserList
                users={users}
                setUsers={setUsers}
                fetching={fetching}
                currentPage={currentPage}
                getAdminList={getAdminList}
                isFiltering={isFiltering}
              />
            </div>
            <div className='card-footer pt-5 p-0' style={{borderTop: '0px'}}>
              <Pagination
                currentPage={currentPage}
                lastPage={pages}
                maxLength={3}
                setCurrentPage={setCurrentPage}
                fetching={fetching}
              />
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <div className='card-header p-0' style={{borderBottom: '0px'}}>
              <div className='row p-0' style={{width: '-webkit-fill-available'}}>
                <div className='col text-start'>
                  <Search
                  setFiltersAssignment={setFiltersAssignment}
                  filtersAssignment={filtersAssignment}
                  isFiltering={isFiltering} 
                  setIsFiltering={setIsFiltering}
                  getAdminListAssignment={getAdminListAssignment}/>
                </div>
                <div className='col text-end'>
                  <CreateEntry setRefetch={setRefetch} />
                </div>
              </div>
            </div>
            <div className='card-body p-0'>
              <UserAssignments 
              refetch={refetch} 
              setRefetch={setRefetch}
              userAssignmentsList={userAssignmentsList}
              setUserAssignmentsList={setUserAssignmentsList}
              setAssignmentPage={setAssignmentPage}
              assignmentPage={assignmentPage}
              setCurrentAssignmentPage={setCurrentAssignmentPage}
              currentAssignmentPage={currentAssignmentPage}
              createUser={createUser}
               />
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
            <div className='card-header p-0'>
              <YearConfiguration />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPage
