import axios from 'axios'
import JwtService from '../../services/JwtService'
import * as mutations from './mutation' 
const apiServerUrl = process.env.REACT_APP_STRAPI_URL



// GET ACTIONS
export const getUserAssignments = (dispatch, page) => {
  return axios.get(`${apiServerUrl}/api/user-assignments?populate=*&pagination[pageSize]=5&pagination[page]=${page}`).then((response) => {
    dispatch(mutations.setUserAssignments(response.data.data.length > 0 ? response.data.data : []))
    return response.data.meta
  })
}
export const getActivities = (dispatch, id) => {
  return axios
    .get(
      `${apiServerUrl}/api/activity-logs?filters[$or][0][owner][id][$eq]=${id}&filters[$or][1][by][id][$eq]=${id}&populate=*&sort[0]=createdAt%3Adesc`
    )
    .then((response) => {
      dispatch(mutations.setActivityLogs(response.data.data))
    })
}
export const getActivitiesById = (id) => {
  return axios
    .get(
      `${apiServerUrl}/api/activity-logs?filters[owner][id][$eq]=${id}&filters[$or][0][action][$eq]=update&filters[$or][1][action][$eq]=delete&populate=*`
    )
    .then((response) => {
      return response.data.data
    })
}
export const getExistingUser = (email) => {
  let filter = ''
  email.forEach((e) => {
    filter += `&filters[email][$eq]=${e}`
  })
  return axios.get(`${apiServerUrl}/api/users?${filter}`).then((response) => {
    if (response.data.length > 0) {
      return response.data
    } else {
      return null
    }
  })
}
export const getTotal = () => {
  let filter = 'fields[0]=id'

  return axios.get(`${apiServerUrl}/api/users?${filter}`).then((response) => {
    if (response.data.length > 0) {
      return response.data
    } else {
      return null
    }
  })
}
export const getTotalAssignment = () => {
  let filter = 'fields[0]=id'

  return axios.get(`${apiServerUrl}/api/user-assignments?${filter}`).then((response) => {
    if (response.data.data.length > 0) {
      return response.data.data
    } else {
      return null
    }
  })
}
export const getAdminUser = (filters, page, isFiltering) => {
  let filter = `sort[0]=full_name%3Aasc&populate=*,supervisees.employee,supervisees.employee.job_titles,supervisees.employee.office_assignments,supervisees.employee.office_assignments.office,supervisees.supervised_by,supervisors.employee,supervisors.supervised_by,job_assignments.job_title,office_assignments.office`
  if (isFiltering === true) {
    filter += ''
  } else {
    filter += `&start=${page}&limit=10`
  }
  if (filters.Search !== '') {
    filter += `&filters[full_name][$containsi]=${filters.Search}`
  }
  if (filters.Supervisor !== '') {
    filter += `&filters[supervisors][supervised_by][id][$eq]=${filters.Supervisor}`
  }
  if (filters.Position !== '') {
    filter += `&filters[job_assignments][job_title][id][$eq]=${filters.Position}`
  }
  if (filters.Office !== '') {
    filter += `&filters[office_assignments][office][id][$eq]=${filters.Office}`
  }
  return axios.get(`${apiServerUrl}/api/users?${filter}`).then((response) => {
    if (response.data.length > 0) {
      return response.data
    } else {
      return null
    }
  })
}
export const getAdminUserAssignment = (filters, page, isFiltering) => {
  let filter = `populate=*&sort[0]=fullname%3Aasc`
  if (isFiltering === true) {
    filter += ''
  } else {
    filter += `&pagination[pageSize]=5&pagination[page]=${page}`
  }
  if (filters.Search !== '') {
    filter += `&filters[fullname][$containsi]=${filters.Search}`
  }
  if (filters.newSupervisorName !== '') {
    filter += `&filters[supervisors][supervised_by][id][$eq]=${filters.newSupervisorName}`
  }
  if (filters.newSupervisorEmail !== '') {
    filter += `&filters[job_assignments][job_title][id][$eq]=${filters.newSupervisorEmail}`
  }
  if (filters.newSupervisorExist !== '') {
    filter += `&filters[office_assignments][office][id][$eq]=${filters.newSupervisorExist}`
  }
  return axios.get(`${apiServerUrl}/api/user-assignments?${filter}`).then((response) => {
    if (response.data.data.length > 0) {
      return response.data.data
    } else {
      return null
    }
  })
}
export const getUsers = (dispatch) => {
  return axios

    .get(
      `${apiServerUrl}/api/users?sort[0]=full_name%3Aasc&populate=*,supervisees.employee,supervisees.employee.job_assignments.job_title,supervisees.employee.office_assignments,supervisees.employee.office_assignments.office,supervisees.supervised_by,supervisors.employee,supervisors.supervised_by,job_assignments.job_title,office_assignments.office`
    )

    .then((response) => {
      dispatch(mutations.setAllUsers(response.data))
    })
}
export const getJobTitles = (dispatch) => {
  return axios
    .get(`${apiServerUrl}/api/job-titles?populate=*,sort[0]=name%3Aasc&pagination[pageSize]=99`)
    .then((response) => {
      dispatch(mutations.setAllJobTitles(response.data.data))
    })
}
export const getOffices = (dispatch) => {
  return axios.get(`${apiServerUrl}/api/offices?populate=*,sort[0]=name%3Aasc`).then((response) => {
    dispatch(mutations.setAllOffices(response.data.data))
  })
}
export const getCurrentPresident = (dispatch) => {
  return axios.get(`${apiServerUrl}/api/users?filters[is_president][$eq]=true`).then((response) => {
    dispatch(mutations.setCurrentPresident(response.data))
  })
}
export const getPPRFormOneById = (id, dispatch) => {
  let filters = `filters[ppr_form][id][$eq]=${id}&populate=*`
  return axios.get(`${apiServerUrl}/api/ppr-form-ones?${filters}`).then((response) => {
    dispatch(mutations.setPart1Result(response.data.data))
  })
}
export const getPPRFormOneAById = (id, dispatch) => {
  let filters = `filters[ppr_form][id][$eq]=${id}&populate=*`
  return axios.get(`${apiServerUrl}/api/ppr-form-one-as?${filters}`).then((response) => {
    dispatch(mutations.setPart1AResult(response.data.data))
  })
}
export const getPPRFormTwoByIdEmployee = (id, dispatch) => {
  let filters = `filters[ppr_form][id][$eq]=${id}&filters[isby_supervisor][$eq]=false&populate=*`
  return axios.get(`${apiServerUrl}/api/ppr-form-twos?${filters}`).then((response) => {
    dispatch(mutations.setPart2Result(response.data.data))
  })
}
export const getPPRFormTwoByIdSupervisor = (id, dispatch) => {
  let filters = `filters[ppr_form][id][$eq]=${id}&filters[isby_supervisor][$eq]=true&populate=*`
  return axios.get(`${apiServerUrl}/api/ppr-form-twos?${filters}`).then((response) => {
    dispatch(mutations.setPart2AResult(response.data.data))
  })
}
export const getPPRFormFiveById = (id, dispatch) => {
  let filters = `filters[ppr_form][id][$eq]=${id}&populate=*`
  return axios.get(`${apiServerUrl}/api/ppr-form-fives?${filters}`).then((response) => {
    dispatch(mutations.setPart5Result(response.data.data))
  })
}
export const getPPRFormSixById = (id, dispatch) => {
  let filters = `filters[ppr_form][id][$eq]=${id}&populate=*`
  return axios.get(`${apiServerUrl}/api/ppr-form-sixes?${filters}`).then((response) => {
    dispatch(mutations.setPart6Result(response.data.data))
  })
}
export const getPPRFormById = (id, year, dispatch) => {
    // update year
    let filters = `filters[employee][id][$eq]=${id}&filters[$and][0][date_review_start][$gt]=${year}-01-01&filters[$and][1][date_review_start][$lt]=${year}-12-31`
    return axios
      .get(`${apiServerUrl}/api/ppr-forms?${filters}&populate=*&pagination[pageSize]=100000`)
      .then((response) => {
        dispatch(mutations.setPart1Result(null))
        dispatch(mutations.setPart5Result(null))
        dispatch(mutations.setPart6Result(null))
        dispatch(mutations.setSelectedPPRForm(response.data.data[0]))
        return response.data.data.length > 0 ? response.data.data[0].id : null
      })
  }

export const getAllCurrentYearPPRForm = (year, dispatch) => {
  let filters = `&filters[$and][0][date_review_start][$gt]=${year}-01-01&filters[$and][1][date_review_start][$lt]=${year}-12-31&populate=*&pagination[pageSize]=100000`
  return axios.get(`${apiServerUrl}/api/ppr-forms?${filters}`).then((response) => {
    dispatch(mutations.setAllCurrentYearPPRForm(response.data.data))
  })
}
export const getCurrentUserPPRFormsByYear = (year, dispatch) => {
  let filters = `filters[manager][id][$eq]=${JwtService.getUserID()}&filters[date_review_start][$gt]=${`${year}-01-01`}&filters[date_review_start][$lt]=${`${year}-12-31&populate=*&pagination[pageSize]=100000`}`
  return axios.get(`${apiServerUrl}/api/ppr-forms?${filters}`).then((response) => {
    dispatch(mutations.setCurrentYearPPRFormByYear(response.data.data))
  })
}

export const getAllCurrentUserPPRForms = () => {
  let filters = `filters[employee][id][$eq]=${JwtService.getUserID()}&filters[employee_approval][$eq]=true&filters[manager_approval][$eq]=true&filters[president_approval][$eq]=true&sort[0]=date_review_start%3Adesc&pagination[pageSize]=100000`
  return axios.get(`${apiServerUrl}/api/ppr-forms?${filters}`).then((response) => {
    if (response.data.data.length > 0) {
      return response.data.data[0]
    } else {
      return null
    }
  })
}
export const getCurrentUserPPRForms = (year, dispatch) => {
  let filters = `filters[employee][id][$eq]=${JwtService.getUserID()}&filters[$and][0][date_review_start][$gt]=${year}-01-01&filters[$and][1][date_review_start][$lt]=${year}-12-31&populate=*`
  return axios
    .get(`${apiServerUrl}/api/ppr-forms?${filters}&pagination[pageSize]=100000`)
    .then((response) => {
      dispatch(mutations.setCurrentYearPPRForm(response.data.data))
      return response.data.data.length > 0 ? response.data.data : null
    })
}
export const getPreviousUserPPRForms = (year, dispatch) => {
  let filters = `filters[employee][id][$eq]=${JwtService.getUserID()}&filters[$and][0][date_review_start][$gt]=${year-1}-01-01&filters[$and][1][date_review_start][$lt]=${year-1}-12-31`
  return axios
    .get(`${apiServerUrl}/api/ppr-forms?${filters}&pagination[pageSize]=100000`)
    .then((response) => {
      dispatch(mutations.setPreviousYearPPRForm(response.data.data))
    })
}
export const getSupervisedStaff = (dispatch) => {
  return axios
    .get(
      `${apiServerUrl}/api/users/${JwtService.getUserID()}?populate=*,supervisees.employee,supervisees.employee.job_assignments.job_title,supervisees.employee.office_assignments,supervisees.employee.office_assignments.office,supervisees.supervised_by,supervisors.employee,supervisors.supervised_by`
    )
    .then((response) => {
      dispatch(mutations.setSupervisedStaff(response.data.supervisees))
    })
}

export const getAllSupervision = (dispatch) => {
  return axios
    .get(
      `${apiServerUrl}/api/employee-supervisions?populate=*,employee.job_assignments.job_title,employee.office_assignments,employee.office_assignments.office&pagination[pageSize]=100000`
    )
    .then((response) => {
      dispatch(mutations.setAllSupervisedStaff(response.data.data))
    })
}
export const getSupervisedStaffById = (id, callback) => {
  return axios
    .get(
      `${apiServerUrl}/api/users/${id}?populate=*,supervisees.employee,supervisees.supervised_by,supervisors.employee,supervisors.supervised_by,job_assignments.job_title.user,office_assignments.office`
    )
    .then((response) => {
      callback(response.data)
    })
}
export const getSelectedPPRUser = (id) => {
  return axios
    .get(`${apiServerUrl}/api/users/${id}?populate=*,supervisees.employee`)
    .then((response) => {
      return response.data
    })
}
export const getUserById = (dispatch) => {
  return axios
    .get(
      `${apiServerUrl}/api/users/${JwtService.getUserID()}?populate=*,supervisees.employee,supervisees.supervised_by,supervisors.employee,supervisors.supervised_by,office_assignments.office,job_assignments.job_title`
    )
    .then((response) => {
      let data = response.data
      if (response.data.supervisees && response.data.supervisees.length > 0)
        Object.assign(data, {isEmployee: false, isSupervisor: true})
      else Object.assign(data, {isEmployee: true, isSupervisor: false})
      if (response.data.is_president === true) {
        data.isEmployee = false
        data.isSupervisor = false
      }
      if (response.data.is_vicepres === true) {
        data.isEmployee = false
        data.isSupervisor = false
      }
      dispatch(mutations.setCurrentUser(data))
      if (response.data.supervisors && response.data.supervisors.length > 0)
        dispatch(mutations.setCurrentUserSupervisor(response.data.supervisors[0].supervised_by))
      if (response.data.office_assignments && response.data.office_assignments.length > 0)
        dispatch(mutations.setCurrentUserOffice(response.data.office_assignments[0].office))
      if (response.data.job_assignments && response.data.job_assignments.length > 0)
        dispatch(mutations.setCurrentUserTitle(response.data.job_assignments[0].job_title))
    })
}

export const getSelectedUserById = (id) => {
  return axios
    .get(
      `${apiServerUrl}/api/users/${id}?populate=*,supervisees.employee,supervisees.supervised_by,supervisors.employee,supervisors.supervised_by,office_assignments.office,job_assignments.job_title`
    )
    .then((response) => {
      return response.data
    })
}
export const getPPRFormByYear = (year, id) => {
  let filters = `filters[employee][id][$eq]=${id}&filters[date_review_start][$gt]=${`${year}-01-01`}&filters[date_review_start][$lt]=${`${year}-12-31&populate=*&pagination[pageSize]=100000`}`
  return axios.get(`${apiServerUrl}/api/ppr-forms?${filters}`).then((response) => {
    return response.data.data
  })
}

export const getAllPPRFormByYear = (id) => {
  let filters = `filters[employee][id][$eq]=${id}&fields[0]=date_review_start&pagination[pageSize]=100000`
  return axios.get(`${apiServerUrl}/api/ppr-forms?${filters}`).then((response) => {
    return response.data.data
  })
}

export const getAllNotifications = (id, dispatch) => {
  return axios
    .get(
      `${apiServerUrl}/api/notifications?populate=*&filters[to][id][$eq]=${id}&filters[read][$eq]=false`
    )
    .then((response) => {
      dispatch(mutations.setNotifications(response.data.data))
      return response.data.data
    })
}
export const getPPRFormYear = (dispatch) => {
  return axios
    .get(`${apiServerUrl}/api/ppr-form-years?&sort[0]=label%3Adesc&populate=*`)
    .then((response) => {
      dispatch(mutations.setPPRFormYear(response.data.data))
      return response.data.data
    })
}
export const getPPRFormYearByYear = (id, year, dispatch) => {
  let filter = `filters[employee][id][$eq]=${id}&filters[date_review_start][$gt]=${`${year}-01-01`}&filters[date_review_start][$lt]=${`${year}-12-31&populate=*&pagination[pageSize]=100000`}`
  return axios.get(`${apiServerUrl}/api/ppr-forms?${filter}`).then((response) => {
    dispatch(mutations.setPPRFormYearByYear(response.data.data))
    return response.data.data
  })
}
export const getUserAssignmentsLogin = async (email) => {
  return axios.get(`${apiServerUrl}/api/user-assignments/?filters[$and][0][email][$contains]=${email}&populate=*`)
  .then(async (response)=>{
    if(response.data.data.length > 0){
      return response.data.data
    }
    else{
      return null
    }
  })
  .catch(function (error) {
    if (error.response) {
      return error.response.status
    }
  })
}
// GET ACTIONS
