/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import {KTSVG} from '../../../../_metronic/helpers'
import {FC,useEffect} from 'react'
import {useSelector} from 'react-redux'
type Props = {
  setFilters: Function
  getAdminList: Function
  setIsFiltering: Function
  filters: Object
  isFiltering: boolean
}
const Filters: FC<Props> = ({setFilters,getAdminList,setIsFiltering,filters,isFiltering}) => {
  const offices = useSelector((state) => state.ppr.offices)
  const jobTitles = useSelector((state) => state.ppr.jobTitles)
  const userList = useSelector((state) => state.ppr.userList)


  const handleChange = (val,key) => {
    setFilters((old) => ({
      ...old,
      [key]: val,
    }))

  }
  useEffect(() => {
    if(filters.Supervisor !== '' || filters.Position !== '' || filters.Office !== '' || filters.Search !== '') {
      setIsFiltering(true)
    }
    else {
      setIsFiltering(false)
    }
  },[filters])

  return (
    <>
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search user'
            onChange={(e) => {
              handleChange(e.target.value,'Search')
            }}
          />
          <div style={{paddingLeft: '10px'}}>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              onClick={() => {getAdminList(1,isFiltering)}}
            >Search</button>
          </div>
        </div>
        {/* end::Search */}
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button
            type='button'
            className='btn btn-light-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
            Filter
          </button>
          {/* end::Filter Button */}
          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Supervisor:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  value={filters.Supervisor}
                  onChange={(e) => {
                    handleChange(e.target.value,'Supervisor')
                  }}
                >
                  <option value=''>--Select--</option>
                  {userList && userList.map((user,index) => (
                    <option value={user.id} key={index}>{user.full_name}</option>
                  ))}
                </select>
              </div>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Position:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  value={filters.Position}
                  onChange={(e) => {
                    handleChange(e.target.value,'Position')
                  }}
                >
                  <option value=''>--Select--</option>
                  {jobTitles && jobTitles.map((title,index) => (
                    <option value={title.id} key={index}>{title.attributes.name}</option>
                  ))}
                </select>
              </div>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Office:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  value={filters.Office}
                  data-hide-search='true'
                  onChange={(e) => {
                    handleChange(e.target.value,'Office')
                  }}
                >
                  <option value=''>--Select--</option>
                  {offices && offices.map((office,index) => (
                    <option value={office.id} key={index}>{office.attributes.name}</option>
                  ))}
                </select>
              </div>
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Role:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                >
                  <option value=''></option>
                  <option value='Administrator'>Administrator</option>
                  <option value='Analyst'>Analyst</option>
                  <option value='Developer'>Developer</option>
                  <option value='Support'>Support</option>
                  <option value='Trial'>Trial</option>
                </select>
              </div> */}
              {/* end::Input group */}

              {/* begin::Input group */}
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Last login:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                >
                  <option value=''></option>
                  <option value='Yesterday'>Yesterday</option>
                  <option value='20 mins ago'>20 mins ago</option>
                  <option value='5 hours ago'>5 hours ago</option>
                  <option value='2 days ago'>2 days ago</option>
                </select>
              </div> */}
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  onMouseDown={() => {
                    handleChange('','Supervisor')
                    handleChange('','Position')
                    handleChange('','Office')
                    setFilters({Supervisor: '',Position: '',Office: '',Search: ''})
                  }}
                  onMouseUp={() => {
                    getAdminList(1,isFiltering)
                  }}
                >
                  Reset
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold px-6'
                  onClick={() => {
                    getAdminList(1,isFiltering)
                  }
                  }
                >
                  Apply
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
      </div>
    </>
  )
}

export {Filters}
