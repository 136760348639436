/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React,{useEffect,useState} from 'react'
import {KTSVG,toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useSelector,useDispatch} from 'react-redux'
import * as actionCreators from '../../../../app/redux/actions'
import {Link} from 'react-router-dom'
import './style.css'

type Props = {
  className: string
}

const ListsWidget2: React.FC<Props> = ({className}) => {
  const currentYearPPRFormByYear = useSelector((state) => state.ppr.currentYearPPRFormByYear)
  const supervisedStaff = useSelector((state) => state.ppr.supervisedStaff)
  const [isFetching,setFetching] = useState(true)
  const [year,setYear] = useState('2022')
  const dispatch = useDispatch()

  useEffect(() => {
    if(supervisedStaff === null) {
      actionCreators.getSupervisedStaff(dispatch)
    } else {
      setFetching(false)
    }
  },[supervisedStaff])

  const getExistingPPR = (employee_id) => {
    let obj =
      currentYearPPRFormByYear &&
      currentYearPPRFormByYear.filter((item) => item.attributes.employee.data && item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      if(obj[0].attributes.is_initial) {
        if(obj[0].attributes.president_approval) {
          return '100%'
        } else if(obj[0].attributes.manager_approval) {
          return '66%'
        } else if(obj[0].attributes.employee_approval) {
          return '33%'
        } else {
          return 'In-Progress'
        }
      } else {
        return 'Not yet submitted'
      }
    } else {
      return 'Not yet submitted'
    }
  }
  const getPage = () => {
    return `${year}`
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Supervised Staff</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Staff under your supervision</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        {isFetching ? (
       <div className="container-body">
       <div className="post-box" style={{flexDirection:"row"}}>
         <div className="avatar"></div>
         <div className="line"></div>
         <div className="line"></div>
       </div>
     
       <div className="post-box">
         <div className="avatar"></div>
         <div className="line"></div>
         <div className="line"></div>
       </div>
     
       <div className="post-box">
         <div className="avatar"></div>
         <div className="line"></div>
         <div className="line"></div>
       </div>
     </div>
        ) : (
          supervisedStaff &&
          supervisedStaff
          .filter((staff) => staff.is_active)
          .map((staff,index) => (
            <div className='d-flex align-items-center mb-7' key={index}>
              <div className='symbol symbol-50px me-5'>
                <img
                  src={
                    staff.employee?.image
                      ? staff.employee.image
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  alt='metronic'
                />
              </div>
              {getExistingPPR(staff.employee.id) === '100%' ?
                <div className='flex-grow-1'>
                  <Link
                    to={`/profile/ppr/${staff.employee.id}/${getPage()}`}
                    className='text-dark fw-bold text-hover-primary fs-6'
                  >
                    {staff.employee.firstname} {staff.employee.lastname}
                  </Link>
                  {staff.employee.job_assignments.map((data,index) => (
                    <span className='text-muted d-block fw-semibold' key={index} >{data.job_title.name}</span>
                  ))}
                </div>
                :
                <div className='flex-grow-1'>
                  <Link
                    to={`/profile/overview/${staff.employee.id}`}
                    className='text-dark fw-bold text-hover-primary fs-6'
                  >
                    {staff.employee.firstname} {staff.employee.lastname}
                  </Link>
                  {staff.employee.job_assignments.map((data,index) => (
                    <span className='text-muted d-block fw-semibold' key={index} >{data.job_title.name}</span>
                  ))}
                </div>}

            </div>
          ))
        )}
      </div>
    </div>
  )
}

export {ListsWidget2}
