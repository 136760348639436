import axios from 'axios'
import JwtService from '../../services/JwtService'
import * as updateAction from './update'
import moment from 'moment'
const apiServerUrl = process.env.REACT_APP_STRAPI_URL

// CREATE ACTIONS
export const createUserAssignments = (payload) => {
  return new Promise(async (resolve, reject) => {
    let body = []
    if(payload.job === 'Custom Job Title'){
      await axios
     .post(`${apiServerUrl}/api/job-titles`, {
      data: {
       name: payload.customJobTitle,
      },
    })
    .then(async (res) => {
      body.push({
        job_title: res.data.data.id,
        office: parseInt(payload.office),
        fullname: payload.firstname + ' ' + payload.lastname,
        email: payload.email,
        new_supervisor: payload.supervisor ? payload.supervisor : null,
        isApplied: false,
        newSupervisorExist: payload.supervisor ? true : false,
      })
    })
    }
    if (payload.supervisees.length > 0) {
      payload.supervisees.forEach((item) => {
        body.push({
          job_title: item.job_title,
          fullname: item.label,
          office: item.office_assignment,
          current_supervisor: item.supervisor_id,
          email: item.email,
          isApplied: false,
          newSupervisorExist: false,
          new_supervisor_name: payload.firstname + ' ' + payload.lastname,
          new_supervisor_email: payload.email,
        })
      })
    }
    console.log(body)
    let promises = []
    body.forEach((obj) => {
      promises.push(
        new Promise(async (resolve, reject) => {
          await axios
            .get(`${apiServerUrl}/api/user-assignments?filters[email]=${obj.email}`)
            .then(async (res) => {
              if (res.data.data.length > 0) {
                await axios
                  .put(`${apiServerUrl}/api/user-assignments/${res.data.data[0].id}`, {
                    data: obj,
                  })
                  .then((res) => {
                    resolve(res)
                  })
              } else {
                await axios
                  .post(`${apiServerUrl}/api/user-assignments`, {
                    data: obj,
                  })
                  .then((res) => {
                    resolve(res)
                  })
              }
            })
        })
      )
    })
    Promise.all(promises).then((response) => {
      resolve(true)
    })
  })
}
export const createActivityLog = (body) => {
  return axios.post(`${apiServerUrl}/api/activity-logs`, {
    data: body,
  })
}
export const createPPRFormYear = (year) => {
  let body = {
    label: year,
    is_active: false,
    is_completed: false,
    is_vp: false,
    date_review_start: moment(`1/1/${year}`).format('YYYY-MM-DD'),
    date_review_end: moment(`12/31/${year}`).format('YYYY-MM-DD'),
  }
  return axios.post(`${apiServerUrl}/api/ppr-form-years`, {
    data: body,
  })
}
export const createUpdateNotifications = (employee, section) => {
  return axios.post(`${apiServerUrl}/api/notifications`, {
    data: {
      to: employee,
      section: section,
      subject: `PPR Form 2022 - Changes/Updates`,
      ppr_form_owner: employee,
      email_content: `<b>There has been changes made to your PPR Form 2022. Please click the button below to review the changes</b>`,
    },
  })
}
export const createUser = (username, password, email, fullname) => {
  return axios
    .post(`${apiServerUrl}/api/users`, {
      username: username,
      email: email,
      password: password,
      full_name: fullname,
      firstname: fullname,
      confirmed: true,
      role: 1,
    })
    .then(async (response) => {
      return response.data.data
    })
    .catch(async (error) => {
      console.log(error)
    })
}
export const createUserfromAssignments = (username, password, email, fullname, id, userId) => {
  return axios
    .post(`${apiServerUrl}/api/users`, {
      username: username,
      email: email,
      password: password,
      full_name: fullname,
      confirmed: true,
      role: 1,
    })
    .then(async (response) => {
      console.log(response.data)
      let body = {
        new_supervisor_name: null,
        new_supervisor_email: null,
        isApplied: true,
        current_supervisor: parseInt(response.data.id),
        new_supervisor: parseInt(response.data.id),
        newSupervisorExist: true,
      }
      axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
        data: body,
      })
      updateAction.updateAssignments(userId, '1', parseInt(response.data.id))
    })
    .catch(async (error) => {
      console.log(error)
    })
}
export const createUserfromAssignmentsAdmin = (
  username,
  password,
  email,
  fullname,
  office,
  jobTitle,
  id,
  supUser,
  supEmail,
  supFullName,
  newSupervisorExist
) => {
  axios
    .get(`${apiServerUrl}/api/users?filters[email][$containsi]=${email}`)
    .then(async (res) => {
      if (res.data.length > 0) {
        if (!newSupervisorExist) {
          axios
            .post(`${apiServerUrl}/api/users`, {
              username: supUser,
              email: supEmail,
              password: password,
              full_name: supFullName,
              confirmed: true,
              role: 1,
            })
            .then(async (response) => {
              let body = {
                new_supervisor_name: null,
                new_supervisor_email: null,
                isApplied: true,
                current_supervisor: parseInt(response.data.id),
                new_supervisor: parseInt(response.data.id),
                newSupervisorExist: true,
              }
              axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
                data: body,
              })
              updateAction.updateAssignments(res.data[0].id, '1', parseInt(response.data.id))
            })
            .catch(async (error) => {
              console.log(error)
            })
        }
      } else {
        axios
          .get(`${apiServerUrl}/api/users?filters[email][$containsi]=${supEmail}`)
          .then(async (ress) => {
            if (ress.data.length > 0) {
              axios
                .post(`${apiServerUrl}/api/users`, {
                  username: username,
                  email: email,
                  password: password,
                  full_name: fullname,
                  confirmed: true,
                  role: 1,
                })
                .then(async (responseEmail) => {
                  let body = {
                    new_supervisor_name: null,
                    new_supervisor_email: null,
                    isApplied: true,
                    current_supervisor: parseInt(ress.data[0].id),
                    new_supervisor: parseInt(ress.data[0].id),
                    newSupervisorExist: true,
                  }
                  axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
                    data: body,
                  })
                  axios
                    .post(`${apiServerUrl}/api/office-assignments`, {
                      data: {
                        is_active: true,
                        office: office,
                        date_started: new Date(),
                        history: [],
                        employee: parseInt(responseEmail.data.id),
                      },
                    })
                    .then((res) => {
                      return axios
                        .get(
                          `${apiServerUrl}/api/office-assignments/${res.data.data.id}?populate=*`
                        )
                        .then((result) => {
                          return result.data.data.attributes.office.data.attributes.name
                        })
                    })
                  axios
                    .post(`${apiServerUrl}/api/job-assignments`, {
                      data: {
                        is_active: true,
                        job_title: jobTitle,
                        date_started: new Date(),
                        history: [],
                        employee: parseInt(responseEmail.data.id),
                      },
                    })
                    .then((res) => {
                      return axios
                        .get(`${apiServerUrl}/api/job-assignments/${res.data.data.id}?populate=*`)
                        .then((result) => {
                          return result.data.data.attributes.job_title.data.attributes.name
                        })
                    })
                  return responseEmail.data
                })
            } else {
              axios
                .post(`${apiServerUrl}/api/users`, {
                  username: username,
                  email: email,
                  password: password,
                  full_name: fullname,
                  confirmed: true,
                  role: 1,
                })
                .then(async (responseEmail2) => {
                  createUserfromAssignments(
                    supUser,
                    '12345678',
                    supEmail,
                    supFullName,
                    id,
                    parseInt(responseEmail2.data.id)
                  )
                  let body = {
                    new_supervisor_name: null,
                    new_supervisor_email: null,
                    isApplied: true,
                    newSupervisorExist: true,
                  }
                  axios.put(`${apiServerUrl}/api/user-assignments/${id}`, {
                    data: body,
                  })
                  axios
                    .post(`${apiServerUrl}/api/office-assignments`, {
                      data: {
                        is_active: true,
                        office: office,
                        date_started: new Date(),
                        history: [],
                        employee: parseInt(responseEmail2.data.id),
                      },
                    })
                    .then((res) => {
                      return axios
                        .get(
                          `${apiServerUrl}/api/office-assignments/${res.data.data.id}?populate=*`
                        )
                        .then((result) => {
                          return result.data.data.attributes.office.data.attributes.name
                        })
                    })
                  axios
                    .post(`${apiServerUrl}/api/job-assignments`, {
                      data: {
                        is_active: true,
                        job_title: jobTitle,
                        date_started: new Date(),
                        history: [],
                        employee: parseInt(responseEmail2.data.id),
                      },
                    })
                    .then((res) => {
                      return axios
                        .get(`${apiServerUrl}/api/job-assignments/${res.data.data.id}?populate=*`)
                        .then((result) => {
                          return result.data.data.attributes.job_title.data.attributes.name
                        })
                    })
                  return responseEmail2.data
                })
            }
          })
      }
    })
    .catch(async (error) => {
      console.log(error)
    })
}

export const uploadPDF = (file, id, year, user) => {
  let formData = new FormData()
  formData.append('files', file)
  return axios.post(`${apiServerUrl}/api/upload`, formData).then((response) => {
    let file_id = response.data[0].id
    let body = {
      employee: user,
      manager: user.supervisors.length > 0 ? user.supervisors[0].supervised_by : null,
      office: user.office_assignments.length > 0 ? user.office_assignments[0].office : null,
      employee_title: user.job_assignments.length > 0 ? user.job_assignments[0].job_title : null,
      pdf: file_id,
      date_review_start: new Date(parseInt(year), 0, 3, 12),
      date_review_end: new Date(parseInt(year), 11, 31),
    }
    return axios
      .post(`${apiServerUrl}/api/ppr-forms`, {
        data: body,
      })
      .then(async (response) => {
        await createActivityLog({
          part: null,
          action: 'upload',
          by: JwtService.getUserID(),
          data: response.data.data,
          owner: user,
          ppr_form: null,
          ppr_year: new Date(`01/01/${year} 12:00`),
        })
      })
  })
}
// CREATE ACTIONS
