/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {KTSVG,toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import * as actionCreators from '../../../../app/redux/actions'
import {Link} from 'react-router-dom'
import './styles.css'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

type Props = {
  className: string
}
const TablesWidget10: React.FC<Props> = ({className}) => {
  const pprFormYear = useSelector((state) => state.ppr.pprFormYear)
  const [yearList,setYearList] = useState()
  const dispatch = useDispatch()
  const [search,setSearch] = useState('')
  const [progress,setProgress] = useState('0')
  const currentYearPPRFormByYear = useSelector((state) => state.ppr.currentYearPPRFormByYear)
  const supervisedStaff = useSelector((state) => state.ppr.supervisedStaff)
  const [staff,setStaff] = useState()
  const [isFetching,setFetching] = useState(true)
  const [year,setYear] = useState()
  const [loading,setLoading] = useState(false)
  const getPage = () => {
    return `${year}`
  }
  useEffect(() => {
    const fetch = async () => {
      setFetching(true)
      await actionCreators.getCurrentUserPPRFormsByYear(year,dispatch)
      setFetching(false)
    }
    if(loading) {
      fetch()
    }
  },[year])
  useEffect(() => {
    if(pprFormYear) {
      var result = [];
      for(var i in pprFormYear)
        if(pprFormYear[i].attributes.is_active) {
          result.push([pprFormYear[i].attributes.label]);
        }
      const years = result.toString().split(',')
      setYearList(years)
      if(year === undefined) {
        setYear(years[0])
      }
      setLoading(true)
    }
  })
  const getExistingPPR = (employee_id) => {
    let obj =
      currentYearPPRFormByYear &&
      currentYearPPRFormByYear.filter((item) => item.attributes.employee.data && item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      if(obj[0].attributes.is_initial) {
        if(obj[0].attributes.president_approval) {
          return '100%'
        } else if(obj[0].attributes.manager_approval) {
          return '66%'
        } else if(obj[0].attributes.employee_approval) {
          return '33%'
        } else {
          return 'In-Progress'
        }
      } else {
        return 'Not yet submitted'
      }
    } else {
      return 'Not yet submitted'
    }
  }
  const getRatingPPR = (employee_id) => {
    let obj =
      currentYearPPRFormByYear &&
      currentYearPPRFormByYear.filter((item) => item.attributes.employee.data.id === employee_id)
    if(obj && obj.length > 0) {
      if(obj[0].attributes.is_initial) {
        if(obj[0].attributes.president_rating_level) {
          return obj[0].attributes.president_rating_level
        }
        else if(obj[0].attributes.supervisor_rating_level) {
          return obj[0].attributes.supervisor_rating_level
        }
        else if(obj[0].attributes.rating_level) {
          return obj[0].attributes.rating_level
        } 
        else{
          return 'No Rating Data'
        }
      } else {
        return 'Not yet submitted'
      }
    } else {
      return 'Not yet submitted'
    }
  }
  useEffect(() => {
    if(search) {
      const result = supervisedStaff.filter((item) =>
        item.employee.full_name.toLowerCase().includes(search.toLowerCase())
      )
      setStaff(result)
    } else {
      setStaff(supervisedStaff)
    }
  },[search])

  useEffect(() => {
    if(supervisedStaff) setStaff(supervisedStaff)
  },[supervisedStaff])
  useEffect(() => {
    if(progress) {
      if(progress === 'Not yet submitted') {
        let ids = currentYearPPRFormByYear.map((item) => {
          return !item.attributes.is_initial && item.attributes.employee.data.id
        })
        supervisedStaff.forEach((i) => {
          let obj =
            currentYearPPRFormByYear &&
            currentYearPPRFormByYear.filter(
              (item) => item.attributes.employee.data.id === i.employee.id
            )
          if(obj.length === 0) {
            ids.push(i.employee.id)
          }
        })
        const result = supervisedStaff.filter((item) => ids.includes(item.employee.id))
        setStaff(result)
      }
      if(progress === 'In-Progress') {
        let ids = currentYearPPRFormByYear.map((item) => {
          return item.attributes.is_initial && !item.attributes.president_approval && item.attributes.employee.data.id
        })
        const result = supervisedStaff.filter((item) => ids.includes(item.employee.id))
        setStaff(result)
      }
      if(progress === 'Completed') {
        let ids = currentYearPPRFormByYear.map((item) => {
          return item.attributes.president_approval && item.attributes.employee.data.id
        })
        const result = supervisedStaff.filter((item) => ids.includes(item.employee.id))
        setStaff(result)
      }
      if(progress === '0') {
        setStaff(supervisedStaff)
      }
    }
  },[progress])


  const [data, setData] = useState([]);
  useEffect(() => {
    if (staff && staff.length > 0) {
      const mappedData = staff.map((data, index) => ({
        id: index + 1, // Adding 1 to the index to start from 1 instead of 0
        name: `${data.employee && data.employee.firstname}`,
        position: `${data.employee.job_assignments.length > 0 &&
          data.employee.job_assignments[0].job_title.name || ''}`,
        office: `${data.employee.office_assignments.length > 0 &&
          data.employee.office_assignments[0].office.name || ''}`,
        progress:  data.employee && data.employee.id,
        ratingLevel:`${getRatingPPR(data.employee && data.employee.id
        )}`
      }));
      setData(mappedData);
    }
  }, [staff,data]);

  const handleEdit = (id, field, value) => {
    setData(prevData => {
      const newData = [...prevData];
      const index = newData.findIndex(item => item.id === id);
      newData[index][field] = value;
      return newData;
    });
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Your Staff's PPR Submission</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            PPR submitted by staff under your supervision
          </span>
        </h3>
        {/* Todo Export with rating Level */}
        {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-primary'
            style={{width: '5%', marginLeft:'1000px',marginTop:'8px'}}
            data-bs-toggle='modal'
            data-bs-target='#exportModal'
          >
            Export
          </button> */}

          <div className='modal fade' tabIndex={-1} id='exportModal'>
          <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content' style={{width: '90%',marginLeft: '2rem'}} >
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>Export {year}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className="modal-body" style={{ display: 'flex' ,height: '15%' }}>
              <div className='table-responsive tableFixHead' style={{overflowY: 'auto',height: '100%',width: '100%', borderRadius: '5px'}}>
              <table className='table table-striped gy-7 gs-7' style={{ overflowY: 'auto' }}>
                <thead style={{ position: 'sticky', top: '0', backgroundColor: '#fff' }}>
                  <tr>
                    <th>
                      <h3>
                        <strong>Name</strong>
                      </h3>
                    </th>
                    <th>
                      <h3>
                        <strong>Position</strong>
                      </h3>
                    </th>
                    <th>
                      <h3>
                        <strong>Office</strong>
                      </h3>
                    </th>
                    <th>
                      <h3>
                        <strong>Progress</strong>
                      </h3>
                    </th>
                    <th>
                      <h3>
                        <strong>Rating Level</strong>
                      </h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => (
                    <tr key={item.id}>
                      <td>
                        <input
                          type='text'
                          value={item.name}
                          onChange={e => handleEdit(item.id, 'name', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type='text'
                          value={item.position}
                          onChange={e => handleEdit(item.id, 'position', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type='text'
                          value={item.office}
                          onChange={e => handleEdit(item.id, 'office', e.target.value)}
                        />
                      </td>
                      <td className='text-end'>
                                          <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                              <span className='text-muted me-2 fs-7 fw-semibold'>{`${getExistingPPR(
                                                item.progress
                                              )}`}</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                              <div
                                                className='progress-bar bg-primary'
                                                role='progressbar'
                                                style={{
                                                  width: `${getExistingPPR(item.progress
                                                  ).includes('%')
                                                    ? getExistingPPR(
                                                      item.progress
                                                    )
                                                    : '0%'
                                                    }`,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                      <td>
                        <input
                          type='text'
                          value={item.ratingLevel}
                          onChange={e => handleEdit(item.id, 'ratingLevel', e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </div>
            <div className="modal-footer">
            <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
              Cancel
            </button>
            <ReactHTMLTableToExcel
            className='btn btn-primary btn-sm'
            table='ExportTableStaff'
            filename={`Rating Report Staff for ${year}`}
            sheet={`Rating ${year}`}
            buttonText='Export to Excel'
          />

          {/* Separate table for exporting without input fields */}
          <table className='d-none' id='ExportTableStaff'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Office</th>
                <th>Progress</th>
                <th>Rating Level</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.position}</td>
                  <td>{item.office}</td>
                  <td>{item.progress}</td>
                  <td>{item.ratingLevel}</td>
                </tr>
              ))}
            </tbody>
          </table>
            </div>
          </div>
        </div>
      </div>
        <div className='card-toolbar'>
        <ul className='nav'>
            {yearList && yearList.map((name,index) => (
              <li className='nav-item' key={index}>
                <a
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${index === 0 ? 'active' : ''
                    }`}
                  data-bs-toggle='tab'
                  href={`#kt_table_widget_10_tab_${index}`}
                  onClick={() => setYear(name)}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 /> */}
        </div>
      </div>
      {isFetching ? (
        <div className='card-body py-3' style={{height: "350px"}}>
          <div className='row pt-2 pb-3'>
            <div className='col-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search by Employee'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <div className='col-2'>
              <select
                className='form-select'
                aria-label='Select example'
                defaultValue={progress}
                onChange={(e) => {
                  setProgress(e.target.value)
                }}
              >
                <option value='0'>Filter by Progress</option>
                <option value='Completed'>Completed</option>
                <option value='In-Progress'>In-Progress</option>
                <option value='Not yet submitted'>Not yet submitted</option>
              </select>
            </div>
          </div>
          <div className="container-body2">
            <div className="post-box2">
              <div className="avatar2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="avatar2-action"></div>
            </div>
          </div>
          <div className="container-body2">
            <div className="post-box2">
              <div className="avatar2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="avatar2-action"></div>
            </div>
          </div>
          <div className="container-body2">
            <div className="post-box2">
              <div className="avatar2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="line2"></div>
              <div className="avatar2-action"></div>
            </div>
          </div>
          
        </div>
      ) : (
        <div className='card-body py-3'>
          <div className='row pt-2 pb-3'>
            <div className='col-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search by Employee'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <div className='col-2'>
              <select
                className='form-select'
                aria-label='Select example'
                defaultValue={progress}
                onChange={(e) => {
                  setProgress(e.target.value)
                }}
              >
                <option value='0'>Filter by Progress</option>
                <option value='Completed'>Completed</option>
                <option value='In-Progress'>In-Progress</option>
                <option value='Not yet submitted'>Not yet submitted</option>
              </select>
            </div>
          </div>
          <div className='tab-content'>
            {yearList && yearList.map((name,index) => (
              <div
                className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
                id={`kt_table_widget_10_tab_${index}`}
                key={index}
              >
                <div className='table-responsive'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                data-kt-check='true'
                                data-kt-check-target='.widget-9-check'
                              />
                            </div>
                          </th>
                          <th className='min-w-150px'>Staff</th>
                          <th className='min-w-140px'>Office</th>
                          <th className='min-w-120px'>Progress</th>
                          <th className='min-w-100px text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staff &&
                          staff.length > 0 &&
                          staff.filter((data) => data.is_active).map((data,index) => (
                            <tr key={index}>
                              <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-9-check'
                                    type='checkbox'
                                    value='1'
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-45px me-5'>
                                    <img src={data.employee.image ? data.employee.image : toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' />
                                  </div>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {getExistingPPR(data.employee && data.employee.id) === '100%' ?
                                      <Link
                                        to={`/profile/ppr/${data.employee && data.employee.id
                                          }/${getPage()}`}
                                        className='text-dark fw-bold text-hover-primary fs-6'
                                      >
                                        {data.employee && data.employee.firstname}{' '}
                                        {data.employee && data.employee.lastname}
                                      </Link> :
                                      <Link
                                        to={`/profile/ppr/${data.employee && data.employee.id
                                          }/${getPage()}`}
                                        className='text-dark fw-bold text-hover-primary fs-6'
                                      >
                                        {data.employee && data.employee.firstname}{' '}
                                        {data.employee && data.employee.lastname}
                                      </Link>}

                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {data.employee.job_assignments.length > 0 &&
                                        data.employee.job_assignments[0].job_title.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {data.employee.office_assignments.length > 0 &&
                                    data.employee.office_assignments[0].office.name}
                                </span>
                              </td>
                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-muted me-2 fs-7 fw-semibold'>{`${getExistingPPR(
                                      data.employee && data.employee.id
                                    )}`}</span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    <div
                                      className='progress-bar bg-primary'
                                      role='progressbar'
                                      style={{
                                        width: `${getExistingPPR(data.employee && data.employee.id).includes(
                                          '%'
                                        )
                                          ? getExistingPPR(data.employee && data.employee.id)
                                          : '0%'
                                          }`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex justify-content-center flex-shrink-0'>
                                  <Link
                                    to={`/profile/ppr/${data.employee && data.employee.id
                                      }/${getPage()}`}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen021.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {staff && staff.length === 0 && <span>No data found</span>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export {TablesWidget10}
