/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React,{useEffect,useRef} from 'react'
import ApexCharts,{ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
type Props = {
  className: string
  chartColor: string
  chartHeight: string
  overAllRating: Object
}

const MixedWidget10: React.FC<Props> = ({className,chartColor,chartHeight,overAllRating}) => {
  const currentUser = useSelector((state) => state.ppr.currentUser)
  const previousYearPPRForm = useSelector((state) => state.ppr.previousYearPPRForm)
  const selectedPPRForm = useSelector((state) => state.ppr.selectedPPRForm)
  const getText = (level) => {
    if(Math.round(level) === 5) return 'EXTRAORDINARY'
    else if(Math.round(level) === 4) return 'EXCEPTIONAL'
    else if(Math.round(level) === 3) return 'SUCCESSFUL'
    else if(Math.round(level) === 2) return 'SUFFICIENT'
    else if(Math.round(level) === 1) return 'NEEDS IMPROVEMENT'
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
                Overall Performance <br /> Rating
              </a>
              <div className='text-muted fs-7 fw-semibold'>
                Your overall performance rating for <br />
                {moment(overAllRating.attributes.date_review_start).subtract(1,'days').format('ll').toUpperCase()} - {moment(overAllRating.attributes.date_review_end).format('ll').toUpperCase() === `DEC 30, ${moment(overAllRating.attributes.date_review_end).year()}` ? `DEC 31, ${moment(overAllRating.attributes.date_review_end).year()}` : moment(overAllRating.attributes.date_review_end).format('ll').toUpperCase()}
              </div>
            </div>

            <div className={`fw-bold fs-3 text-${chartColor}`}>{overAllRating.attributes.president_rating_level ? overAllRating.attributes.president_rating_level : overAllRating.attributes.supervisor_rating_level ? overAllRating.attributes.supervisor_rating_level : overAllRating.attributes.rating_level}</div>
          </div>
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='text-dark fw-bold fs-2'>{getText(overAllRating.attributes.president_rating_level ? overAllRating.attributes.president_rating_level : overAllRating.attributes.supervisor_rating_level ? overAllRating.attributes.supervisor_rating_level : overAllRating.attributes.rating_level)}
            </div>
            <div>
            {selectedPPRForm || previousYearPPRForm[0] ?
            <>            <a
            href={`${process.env.REACT_APP_STRAPI_URL}/api/ppr-forms/export/${selectedPPRForm ? selectedPPRForm.id : previousYearPPRForm[0].id}`}
            type='button'
            className='btn btn-primary me-2' 
          >
            Export
          </a></>:<></>}
            <Link
              to={`/profile/ppr/${currentUser.id}/${((overAllRating.attributes.date_review_start).split('-',1))}`}
              type='button'
              className='btn btn-primary'
            >
              View
            </Link>
            </div>
          </div>
        </div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {MixedWidget10}
