/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import {FC} from 'react'
import PageLink from './PageLink';
import './Pagination.css'
type Props = {
  currentPage: number;
  lastPage: number;
  maxLength: number;
  setCurrentPage: Function;
  fetching: boolean
}
const Pagination: FC<Props> = ({
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
  fetching}) => {
  const getPaginationItems = (
    currentPage: number,
    lastPage: number,
    maxLength: number
  ) => {
    const res: Array<number> = [];

    // handle lastPage less than maxLength
    if(lastPage <= maxLength) {
      for(let i = 1;i <= lastPage;i++) {
        res.push(i);
      }
    }

    // handle ellipsis logics
    else {
      const firstPage = 1;
      const confirmedPagesCount = 1;
      const deductedMaxLength = maxLength - confirmedPagesCount;
      const sideLength = deductedMaxLength / 2;

      // handle ellipsis in the middle
      if(
        currentPage - firstPage < sideLength ||
        lastPage - currentPage < sideLength
      ) {
        for(let j = 1;j <= sideLength + firstPage;j++) {
          res.push(j);
        }

        res.push(NaN);

        for(let k = lastPage - sideLength;k <= lastPage;k++) {
          res.push(k);
        }
      }

      // handle two ellipsis
      else if(
        currentPage - firstPage >= deductedMaxLength &&
        lastPage - currentPage >= deductedMaxLength
      ) {
        const deductedSideLength = sideLength - 1;

        res.push(1);
        res.push(NaN);

        for(
          let l = currentPage - deductedSideLength;
          l <= currentPage + deductedSideLength;
          l++
        ) {
          res.push(l);
        }

        res.push(NaN);
        res.push(lastPage);
      }

      // handle ellipsis not in the middle
      else {
        const isNearFirstPage = currentPage - firstPage < lastPage - currentPage;
        let remainingLength = maxLength;

        if(isNearFirstPage) {
          for(let m = 1;m <= currentPage + 1;m++) {
            res.push(m);
            remainingLength -= 1;
          }

          res.push(NaN);
          remainingLength -= 1;

          for(let n = lastPage - (remainingLength - 1);n <= lastPage;n++) {
            res.push(n);
          }
        } else {
          for(let o = lastPage;o >= currentPage - 1;o--) {
            res.unshift(o);
            remainingLength -= 1;
          }

          res.unshift(NaN);
          remainingLength -= 1;

          for(let p = remainingLength;p >= 1;p--) {
            res.unshift(p);
          }
        }
      }
    }

    return res;
  }

  const pageNums = getPaginationItems(currentPage,lastPage,maxLength);
  return (
    <nav className="pagination" aria-label="Pagination">
      <PageLink
        disabled={currentPage === 1 || fetching}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        Previous
      </PageLink>
      {pageNums.map((pageNum,idx) => (
        <PageLink
          key={idx}
          active={currentPage === pageNum}
          disabled={isNaN(pageNum) || fetching}
          onClick={() => setCurrentPage(pageNum)}
        >
          {!isNaN(pageNum) ? pageNum : '...'}
        </PageLink>
      ))}
      <PageLink
        disabled={currentPage === lastPage || fetching}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        Next
      </PageLink>
    </nav>
  );
}

export {Pagination}

