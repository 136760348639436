//@ts-nocheck
import clsx from 'clsx'
import React, {FC} from 'react'
import {useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, HeaderNotificationsMenu, UserSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import './styles.css'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {config} = useLayout()
  const currentUser = useSelector((state) => state.ppr.currentUser)
  const notifications = useSelector((state) => state.ppr.notifications)
  const activityLogs = useSelector((state) => state.ppr.activityLogs)

  const combinedList = [
    ...(notifications?.map((item) => ({ ...item, type: 'notification' })) ?? []),
    ...(activityLogs?.map((item) => ({ ...item, type: 'activityLog' })) ?? []),
  ]
  
const sortedList = combinedList.sort((a, b) => {
  if (a.type < b.type) return -1
  if (a.type > b.type) return 1
  if (a.date < b.date) return -1
  if (a.date > b.date) return 1
  return 0
})

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
      </div> */}

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <i className='fas fa-regular fa-bell fs-2'></i>
          {sortedList && sortedList.length > 0 && (
            <span
              className='badge badge-circle badge-danger'
              style={{
                position: 'absolute',
                marginTop: '15px',
                marginLeft: '13px',
                width: '1.5rem',
                height: '1.5rem',
              }}
            >
              {sortedList && sortedList.length}
            </span>
          )}
        </div>
        <HeaderNotificationsMenu />
      </div>

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div> */}

      {/* Quick links */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
      </div> */}

      {/* begin::Theme mode */}
      {process.env.NODE_ENV === 'development' && (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <UserSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
        </div>
      )}
      {/* end::Theme mode */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-40px me-5'>
              <img
                src={
                  currentUser?.image ? currentUser.image : toAbsoluteUrl('/media/avatars/blank.png')
                }
                alt='metronic'
              />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.firstname}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
