//@ts-nocheck
import {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import * as actionCreators from '../../../redux/actions/update'
import * as createAction from '../../../redux/actions/create'
import Swal from 'sweetalert2'
import {Modal} from 'bootstrap'
type UpdateEntryProps = {
  setRefetch: Function
  user: Object
  currentAssignmentPage: Object
  setCurrentAssignmentPage: Function
  getAdminListAssignment: Function
  isFiltering: boolean
}

const UpdateEntry: FC<UpdateEntryProps> = ({setRefetch, user,setCurrentAssignmentPage,currentAssignmentPage,getAdminListAssignment,isFiltering}) => {
  const myModalRef = useRef()
  const [myModal, setMyModal] = useState(null)
  const offices = useSelector((state) => state.ppr.offices)
  const jobTitles = useSelector((state) => state.ppr.jobTitles)
  const userList = useSelector((state) => state.ppr.userList)
  const [form, setForm] = useState({
    job: '',
    office: '',
    new_supervisor: '',
    new_supervisor_email: '',
    new_supervisor_name: '',
  })

  function handleShow() {
    if (!myModal) {
      new Modal(myModalRef.current).show()
    } else {
      myModal.show()
    }
  }

  function handleHide() {
    if (myModal) {
      myModal.hide()
    }
  }
  const onCreate= async (event)=>{
    event.preventDefault()
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,  
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure you want to create this User?',
        text: "User will be created with its current details",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Create',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (res) => {
       if (res.isConfirmed) {
        const email = user.attributes.email;
        const newSupervisorEmail = user.attributes.new_supervisor.data.attributes.email;
        const emailWithoutDomain = email && email.replace(/@(gmail\.com|usasean\.org)/, "");
        const newSupervisorEmailWithoutDomain = newSupervisorEmail && newSupervisorEmail.replace(/@(gmail\.com|usasean\.org)/, "");
        createAction.createUserfromAssignmentsAdmin(
          emailWithoutDomain,
          '12345678',
          email,
          user.attributes.fullname,
          user.attributes.office.data.id,
          user.attributes.job_title.data.id,
          user.id,
          newSupervisorEmailWithoutDomain,
          newSupervisorEmail,
          user.attributes.new_supervisor_name
        );
        setRefetch(false)
        setRefetch(true)
        setTimeout(() => {
        getAdminListAssignment(currentAssignmentPage,isFiltering);
        }, 1100);
          }
        })
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Create',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          await actionCreators.updateUserAssignments(form, user.id).then(() => {
            handleHide()
          })
        }
      })
  }
  useEffect(() => {
    setMyModal(new Modal(myModalRef.current))
  }, [])
  const handleChange = (val, field) => {
    setForm((old) => ({
      ...old,
      [field]: val,
    }))
  }
  useEffect(() => {
    if (user) {
      setForm((old) => ({
        ...old,
        job: user.attributes.job_title.data ? user.attributes.job_title.data.id : '',
        office: user.attributes.office.data ? user.attributes.office.data.id : '',
        new_supervisor: user.attributes.new_supervisor.data
          ? user.attributes.new_supervisor.data.id
          : '',
        new_supervisor_email: user.attributes.new_supervisor_email
          ? user.attributes.new_supervisor_email
          : '',
        new_supervisor_name: user.attributes.new_supervisor_name
          ? user.attributes.new_supervisor_name
          : '',
      }))
    }
  }, [user])
  useEffect(() => {
    if (form) {
      if (form.new_supervisor_email !== '' || form.new_supervisor_name) {
        handleChange('', 'new_supervisor')
      }
    }
  }, [form.new_supervisor_email, form.new_supervisor_name])
  useEffect(() => {
    if (form) {
      if (form.new_supervisor !== '') {
        handleChange('', 'new_supervisor_email')
        handleChange('', 'new_supervisor_name')
      }
    }
  }, [form.new_supervisor])
  return (
    <>
    <button
        className='btn btn-sm btn-icon btn-primary'
        style={{whiteSpace: 'nowrap', width: '80px',marginRight: '10px'}}
        onClick={onCreate}
        disabled={user.attributes.isApplied}
      >
        Create
      </button>
      <button
        className='btn btn-sm btn-icon btn-primary'
        style={{whiteSpace: 'nowrap', width: '80px'}}
        onClick={handleShow}
      >
        Update
      </button>
      <div className='modal fade' ref={myModalRef} tabIndex={-1} id='createEntryModal'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header' style={{height: '15%'}}>
              <h5 className='modal-title'>Update {'🡪'} User Details & Assignments</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleHide}
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <form onSubmit={onSubmit}>
                <div className='row'>
                  <div className='col-6'>
                    <label className='form-label fs-5'>Name:&nbsp;&nbsp;</label>
                    <span className='fs-5'>{user.attributes.fullname}</span>
                    <br />
                    <label className='form-label fs-5'>Email:&nbsp;&nbsp;</label>
                    <span className='fs-5'>{user.attributes.email}</span>
                  </div>
                  <div className='col-6'>
                    <label className='form-label fs-5'>Current Supervisor:&nbsp;&nbsp;</label>
                    <span className='fs-5'>
                      {user && user.attributes.current_supervisor.data
                        ? user.attributes.current_supervisor.data.attributes.full_name
                        : 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='row pt-5'>
                  <div className='col-6'>
                    <label className='form-label required'>Assign Job Title</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      required
                      value={form.job}
                      onChange={(e) => {
                        handleChange(e.target.value, 'job')
                      }}
                    >
                      <option value=''> - Select - </option>
                      {jobTitles &&
                        jobTitles.map((title, index) => {
                          return (
                            <option value={title.id} key={index}>
                              {title.attributes.name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  <div className='col-6'>
                    <label className='form-label required'>Assign Office</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      value={form.office}
                      required
                      onChange={(e) => {
                        handleChange(e.target.value, 'office')
                      }}
                    >
                      <option value=''> - Select - </option>
                      {offices &&
                        offices.map((office, index) => {
                          return (
                            <option value={office.id} key={index}>
                              {office.attributes.name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
                <div className='row pt-5'>
                  <div className='col-12'>
                    <label className={`form-label ${form.new_supervisor === '' ? '' : 'required'}`}>
                      Select a new supervisor
                    </label>
                    <select
                      className={`form-select ${form.new_supervisor === '' ? 'bg-secondary' : ''}`}
                      aria-label='Select example'
                      value={form.new_supervisor}
                      required={form.new_supervisor_email === '' && form.new_supervisor_name}
                      onChange={(e) => {
                        handleChange(e.target.value, 'new_supervisor')
                      }}
                    >
                      <option value=''> - Select - </option>
                      {userList &&
                        userList.map((user, index) => {
                          return (
                            <option value={user.id} key={index}>
                              {user.full_name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
                <div className='row pt-5 text-center'>
                  <label className='form-label'>Or enter a new supervisor by name and email</label>
                </div>
                <div className='row '>
                  <div className='col-6'>
                    <label className={`form-label ${form.new_supervisor === '' ? 'required' : ''}`}>
                      Supervisor Name
                    </label>
                    <input
                      type='text'
                      className={`form-control ${form.new_supervisor === '' ? '' : 'bg-secondary'}`}
                      value={form.new_supervisor_name}
                      placeholder="Enter new supervisor's name"
                      required={form.new_supervisor === ''}
                      onChange={(e) => {
                        handleChange(e.target.value, 'new_supervisor_name')
                      }}
                    />
                  </div>
                  <div className='col-6'>
                    <label className={`form-label ${form.new_supervisor === '' ? 'required' : ''}`}>
                      Supervisor Email
                    </label>
                    <input
                      type='email'
                      className={`form-control ${form.new_supervisor === '' ? '' : 'bg-secondary'}`}
                      value={form.new_supervisor_email}
                      placeholder="Enter new supervisor's email"
                      required={form.new_supervisor === ''}
                      onChange={(e) => {
                        handleChange(e.target.value, 'new_supervisor_email')
                      }}
                    />
                  </div>
                </div>
                <div className='row pt-5'>
                  <div className='col text-center'>
                    <button className='btn btn-sm btn-primary' type='submit'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UpdateEntry
