//@ts-nocheck
import {useEffect, useState} from 'react'
import * as actionCreators from '../../../redux/actions/index'
import UpdateEntry from './updateEntry'
import SyncEntry from './syncEntry'
import {Pagination} from './Pagination'
import './styles.css'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'

type Props = {
  refetch: boolean
  setRefetch: Function
  userAssignmentsList: Object
  setUserAssignmentsList: Function
  setAssignmentPage: Function
  assignmentPage: Object
  setCurrentAssignmentPage: Function
  currentAssignmentPage: Object
  createUser: Function
}

const UserAssignments: FC<Props> = ({refetch, setRefetch,userAssignmentsList,setUserAssignmentsList,setAssignmentPage,assignmentPage,setCurrentAssignmentPage,currentAssignmentPage,createUser}) => {
  // const userAssignments = useSelector((state) => state.ppr.userAssignments)
  // const dispatch = useDispatch()

  // const getUserAssignments = async () => {
  //   setFetching(true)
  //   await actionCreators.getUserAssignments(dispatch, currentPage).then((result) => {
  //     setPages(result.pagination.pageCount)
  //   })
  //   setRefetch(false)
  //   setFetching(false)
  // }
  // useEffect(() => {
  //   getUserAssignments()
  // }, [currentPage])
  // useEffect(() => {
  //   console.log(userAssignments)
  // }, [userAssignments])
  // useEffect(() => {
  //   if (refetch) {
  //     console.log('refetching')
  //     getUserAssignments()
  //   }
  // }, [refetch])
  return (
    <>
      <div className='table-responsive'>
        <table
          className='table table-rounded table-row-bordered border gy-7 gs-7 table-hover'
          style={{verticalAlign: 'middle'}}
        >
          <thead>
            <tr className='fw-bolder fs-6 text-gray-800'>
              <th></th>
              <th>Name</th>
              <th>Current Supervisor</th>
              <th>New Supervisor</th>
              <th>Office</th>
              <th>Job Title</th>
              <th>Applied</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!refetch ? (
              userAssignmentsList && userAssignmentsList.length > 0 ? (
                userAssignmentsList.map((user, index) => (
                  <tr key={index}>
                    <td></td>
                    <td>{user.attributes.fullname}</td>
                    <td>
                      {user.attributes.current_supervisor.data
                        ? user.attributes.current_supervisor.data.attributes.full_name
                        : 'N/A'}
                    </td>
                    <td>
                      {user.attributes.newSupervisorExist
                        ? `${user.attributes.new_supervisor.data.attributes.full_name} (${
                            user.attributes.current_supervisor.data ? 'Replacement' : 'Assignment'
                          })`
                        : `${
                            user.attributes.new_supervisor_name
                              ? user.attributes.new_supervisor_name
                              : 'N/A'
                          } (${
                            user.attributes.current_supervisor.data ? 'Replacement' : 'Assignment'
                          })`}
                    </td>
                    <td>
                      {user.attributes.office.data
                        ? user.attributes.office.data.attributes.name
                        : 'N/A'}
                    </td>
                    <td>
                      {user.attributes.job_title.data
                        ? user.attributes.job_title.data.attributes.name
                        : 'N/A'}
                    </td>
                    <td>{!user.attributes.isApplied ? 'No' : 'Yes'}</td>
                    <td>
                      <UpdateEntry setRefetch={setRefetch} user={user} />
                      &nbsp;
                      <SyncEntry setRefetch={setRefetch} user={user} createUser={createUser} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td rowSpan={7}>No records found.</td>
                </tr>
              )
            ) : (
              <tr>
                <td rowSpan={7} style={{height: '500px'}}>
                  <div
                    className='overlay-wrapper p-5 position-absolute start-50 translate-middle'
                    style={{top: '60%'}}
                  >
                    <div
                      className='spinner-border text-primary'
                      style={{width: '13rem', height: '13rem'}}
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='row'>
      <Pagination
        currentPage={currentAssignmentPage}
        lastPage={assignmentPage}
        maxLength={3}
        setCurrentPage={setCurrentAssignmentPage}
        fetching={refetch}
      />
    </div>
    </>
  )
}

export default UserAssignments
